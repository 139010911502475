import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { merge } from "lodash";
import queryString from "query-string";
import localStorageHelper, { KeyStorage } from "./localStorage";
import { Session } from "./session";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 5 * 60 * 1000,
  paramsSerializer: (params) => queryString.stringify(params),
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorageHelper.getObject<Session | null>(
      KeyStorage.SESSION,
      null
    );
    if (token) {
      config.headers!["Authorization"] = "Bearer " + token.token;
      config.headers!["Content-Type"] = "application/json;charset=UTF-8";
      config.headers!["Access-Control-Allow-Origin"] = "*";
    }
    return config;
  },
  (error: AxiosError) => {
    // Do something with request error
    if (!error.response) {
      return { message: error.message, error: error.message };
    }
    return error.response.data;
  }
);

instance.interceptors.response.use(
  async (response: any) => {
    const originalConfig = response.config;

    if (
      (response.data?.status === "INVALID_TOKEN" ||
        response.data?.status === "UNAUTHORIZED") &&
      // @ts-ignore
      !originalConfig._retry
    ) {
      const token = localStorageHelper.getObject<Session | null>(
        KeyStorage.SESSION,
        null
      );
      // @ts-ignore
      originalConfig._retry = true;
      try {
        const rs = await instance.post("/user/refresh-token", {
          refreshToken: "Bearer " + token?.refreshToken,
        });
        localStorageHelper.setObject(KeyStorage.SESSION, merge(token, rs));
        return instance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }

    return response.data;
  },
  async (error: AxiosError<any>) => {
    const response = error?.response;
    if (typeof error.response?.data === "object") {
      if (response?.status) {
        return {
          error: error,
          message: error.message,
          ...response?.data,
        };
      }
    }
    return { message: error.message, error: error.message };
  }
);

export type HeaderConf = {
  authorization?: boolean;
  locale?: string;
} & Record<string, unknown>;

export type Res<T = any> = T & {
  error?: string;
  message?: string;
};

export type ListParams<T = any> = T & {
  limit?: number;
  page?: number;
};

export type ListRes<T = any> = Res<{
  items: T[];
  total: number;
}>;

const axiosClient = {
  async get<T = any, P = Record<string, unknown>>(url: string, params?: P) {
    return instance.get<any, T>(url, { params });
  },
  async post<T = any, D = Record<string, unknown>>(
    url: string,
    data: D,
    headerConf?: HeaderConf
  ) {
    return instance.post<any, T>(url, data);
  },
  async postFormData<T = any, D = Record<string, unknown>>(
    url: string,
    data: FormData,
    headerConf?: HeaderConf
  ) {
    return instance.post<any, T>(url, data);
  },
  async put<T = any, D = Record<string, unknown>>(
    url: string,
    data: D,
    headerConf?: HeaderConf
  ) {
    return instance.put<any, T>(url, data);
  },
  async delete<T = any>(url: string, headerConf?: HeaderConf) {
    return instance.delete<any, T>(url);
  },
};

export default axiosClient;
