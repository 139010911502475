import React, { useState } from "react";
import { createGlobalStyle } from "styled-components";
import Category from "../components/Category";
import Footer from "../components/Footer";
import FeatureBox from "../components/FeatureBox";
import HotCollections from "../components/HotCollections";
import NewItems from "../components/NewItems";
import SliderHome from "../components/SliderHome";
import { Link } from "react-router-dom";
import Header from "../menu/Header";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
`;

const Home = () => {
  const [openWallet, setOpenWallet] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  const onClickToOpenRegisterModal = () => {
    setOpenRegisterModal(true);
  };

  return (
    <div>
      <GlobalStyles />
      <Header
        openWallet={openWallet}
        setOpenWallet={setOpenWallet}
        openRegisterModal={openRegisterModal}
        setOpenRegisterModal={setOpenRegisterModal}
      />
      <section className="jumbotron no-bg">
        <SliderHome onClickToOpenRegisterModal={onClickToOpenRegisterModal} />
      </section>

      <section className="container">
        <Category />
      </section>

      <section className="container no-top no-bottom">
        <div className="row justify-content-md-center col-10 m-auto justify-content-center">
          <div className="mb-2 d-flex justify-content-between align-items-center">
            <h3
              className="color-full"
              style={{ fontSize: "28px", fontWeight: "900" }}
            >
              Trending deals
            </h3>
            <Link to="/products" className="view-all">
              View All
            </Link>
          </div>

          <HotCollections />
        </div>
      </section>

      <section className="container no-top no-bottom mt-60">
        <div className="row justify-content-md-center col-10 m-auto justify-content-center">
          <div className="mb-2 d-flex justify-content-between align-items-center">
            <h3
              className="color-full"
              style={{ fontSize: "28px", fontWeight: "900" }}
            >
              See what’s new
            </h3>
            <Link to="/products" className="view-all">
              View All
            </Link>
          </div>
          <NewItems onClickToOpenRegisterModal={onClickToOpenRegisterModal} />
        </div>
      </section>

      <section className="feature-section no-top no-bottom mt-60">
        <FeatureBox />
      </section>

      <Footer />
    </div>
  );
};
export default Home;
