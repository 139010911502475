import { InjectedConnector } from "@web3-react/injected-connector";

export const POLYGON_MAINNET_CHAINID = 137;
export const POLYGON_TESTNET_CHAINID = 80001;

export const supportedChainIds = [POLYGON_MAINNET_CHAINID];

export const ChainInfos = {
  137: {
    chainIdHex: `0x${Number(137).toString(16)}`,
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      decimals: 18,
      symbol: "MATIC",
    },
    blockExplorer: "https://polygonscan.com",
  },
  80001: {
    chainIdHex: `0x${Number(80001).toString(16)}`,
    rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
    chainName: "Mumbai Testnet",
    nativeCurrency: {
      name: "MATIC",
      decimals: 18,
      symbol: "MATIC",
    },
  },
};

const injected = new InjectedConnector({
  supportedChainIds,
});

export const connectors = {
  injected,
};
