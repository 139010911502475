import React from "react";

export const Discord = ({ width = 22, height = 16, color = "#6902C0" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0244 2.51998C17.5566 1.34564 15.7951 0.758511 13.9358 0.660645L13.6422 0.954245C15.3058 1.34564 16.7737 2.12851 18.1437 3.20498C16.4801 2.32424 14.6208 1.73711 12.6636 1.54138C12.0764 1.44351 11.5871 1.44351 11 1.44351C10.4128 1.44351 9.92351 1.44351 9.33638 1.54138C7.37918 1.73711 5.51985 2.32424 3.85625 3.20498C5.22625 2.12851 6.69418 1.34564 8.35778 0.954245L8.06418 0.660645C6.20485 0.758511 4.44338 1.34564 2.97551 2.51998C1.31191 5.65151 0.431173 9.17445 0.333313 12.7952C1.80118 14.361 3.85625 15.3396 6.00918 15.3396C6.00918 15.3396 6.69418 14.5567 7.18345 13.8716C5.91131 13.5781 4.73698 12.893 3.95411 11.8166C4.63911 12.208 5.32411 12.5995 6.00918 12.893C6.88991 13.2845 7.77065 13.4802 8.65138 13.676C9.43425 13.7738 10.2171 13.8716 11 13.8716C11.7828 13.8716 12.5657 13.7738 13.3486 13.676C14.2293 13.4802 15.11 13.2845 15.9908 12.893C16.6758 12.5995 17.3608 12.208 18.0458 11.8166C17.263 12.893 16.0886 13.5781 14.8165 13.8716C15.3058 14.5567 15.9908 15.3396 15.9908 15.3396C18.1437 15.3396 20.1988 14.361 21.6666 12.7952C21.5688 9.17445 20.688 5.65151 19.0244 2.51998ZM7.77065 11.0337C6.79205 11.0337 5.91131 10.153 5.91131 9.07658C5.91131 8.00011 6.79205 7.11938 7.77065 7.11938C8.74925 7.11938 9.62998 8.00011 9.62998 9.07658C9.62998 10.153 8.74925 11.0337 7.77065 11.0337ZM14.2293 11.0337C13.2507 11.0337 12.37 10.153 12.37 9.07658C12.37 8.00011 13.2507 7.11938 14.2293 7.11938C15.2079 7.11938 16.0886 8.00011 16.0886 9.07658C16.0886 10.153 15.2079 11.0337 14.2293 11.0337Z"
        fill={color}
      />
    </svg>
  );
};
