import React from "react";

export const LongRightArrowICon = ({
  width = 52,
  height = 12,
  color = "#6902C0",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.03 6.6563C51.3229 6.36341 51.3229 5.88853 51.03 5.59564L46.257 0.822669C45.9641 0.529776 45.4893 0.529776 45.1964 0.822669C44.9035 1.11556 44.9035 1.59044 45.1964 1.88333L49.439 6.12597L45.1964 10.3686C44.9035 10.6615 44.9035 11.1364 45.1964 11.4293C45.4893 11.7222 45.9641 11.7222 46.257 11.4293L51.03 6.6563ZM0.552246 6.87598L50.4997 6.87597L50.4997 5.37597L0.552246 5.37598L0.552246 6.87598Z"
        fill={color}
      />
    </svg>
  );
};
