import React, { useEffect, useState } from "react";
import { Pagination, Spinner } from "react-bootstrap";
import { createGlobalStyle } from "styled-components";
import Footer from "../components/Footer";
import { isEmpty } from "lodash";
import { collectionByUser } from "../../services/services";
import styled from "styled-components";
import { get } from "lodash";
import Header from "../menu/Header";
import { truncateString } from "../../utils/utils";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 15px;
`;

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1);
  }
`;

const Collections = () => {
  const [dataCollections, setDataCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  // const [filter, setFilter] = useState({
  //   page: 1,
  //   limit: 8,
  // });
  const [height, setHeight] = useState(0);
  const [openImage, setOpenImage] = useState(false);
  const [urlImage, setUrlImage] = useState("");
  const [openWallet, setOpenWallet] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  useEffect(() => {
    onGetDataCollections();
  }, []);

  const onGetDataCollections = async () => {
    setLoading(true);
    try {
      const res = await collectionByUser();
      if (res && res?.status === "SUCCESS") {
        setDataCollections(res?.data);
        setTotal(res?.total);
      } else {
        setDataCollections(res?.data);
        setTotal(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // const onChangePage = (number) => {
  //   setFilter({ ...filter, page: number });
  // };

  // const renderPagination = (totalTable) => {
  //   const limitPage = Math.ceil(totalTable / filter.limit);
  //   const active = filter.page;
  //   const items = [];
  //   for (let number = 1; number <= limitPage; number++) {
  //     items.push(
  //       <Pagination.Item
  //         onClick={() => onChangePage(number)}
  //         key={number}
  //         active={number === active}
  //       >
  //         {number}
  //       </Pagination.Item>
  //     );
  //   }

  //   return <Pagination>{items}</Pagination>;
  // };

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };

  return (
    <div>
      <GlobalStyles />
      <Header
        openWallet={openWallet}
        setOpenWallet={setOpenWallet}
        openRegisterModal={openRegisterModal}
        setOpenRegisterModal={setOpenRegisterModal}
      />
      <section className="container">
        <div className="row">
          <div className="spacer-double"></div>
          <div className="col-md-5 text-nft color-full">My Items</div>
          <div className="col-md-12">
            <div className="row">
              {loading ? (
                <div className="text-center mt-5">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : isEmpty(dataCollections) ? (
                <div className="mt-5 text-center no-data">No Data</div>
              ) : (
                <>
                  {dataCollections.map((nft, index) => {
                    return (
                      <div
                        className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4"
                        key={`list_${index + 1}`}
                      >
                        <div className="nft__item">
                          <div
                            className="nft__item_wrap"
                            style={{ height: `${300}px` }}
                          >
                            <Outer>
                              <img
                                onClick={() => {
                                  setOpenImage(true);
                                  setUrlImage(get(nft, "value[0]"));
                                }}
                                src={
                                  nft.value.length ? get(nft, "value[0]") : ""
                                }
                                className="lazy nft__item_preview cursor-pointer"
                                alt={truncateString(nft?.name, 32)}
                              />
                            </Outer>
                          </div>
                          <div className="nft__item_info">
                            <span>
                              <h4>{truncateString(nft?.name, 32)}</h4>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="col-lg-12">
                    <div className="spacer-single"></div>
                    <div className="pagination-flex">
                      {total > 0 ? <div>{renderPagination(total)}</div> : ""}
                    </div> 
                  </div> */}

                  {openImage && (
                    <div className="checkout">
                      <div className="maincheckout main-img">
                        <button
                          className="btn-close"
                          onClick={() => setOpenImage(false)}
                        >
                          x
                        </button>
                        <div className="mt-60">
                          <img
                            onLoad={onImgLoad}
                            className="lazy nft__item_preview"
                            src={urlImage}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Collections;
