import { useEffect, createContext } from "react";
import { osName, isSamsungBrowser } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (osName === "iOS") {
      navigate("/suspended");
    } else if (osName === "Android" || isSamsungBrowser) {
      navigate("/download");
    } else {
      if (
        location.pathname === "/download" ||
        location.pathname === "/suspended"
      ) {
        navigate("/");
      }
    }
  }, []);
  return <AppContext.Provider>{children}</AppContext.Provider>;
};

export default AppProvider;
