import axiosClient from "../utils/axiosClient";

export const onGetCollectionStatistic = async () => {
  const res = await axiosClient.get("/collection/statistic");
  return res;
};

export const onGetHotCollections = async () => {
  return axiosClient.get("/collection/hot-collections");
};

export const onGetNewCollections = async () => {
  return await axiosClient.get("/collection/new-collections");
};

export const onGetCategory = async () => {
  const res = await axiosClient.get("/categories");
  return res;
};

export const onGetCompany = async (params: any) => {
  const res = await axiosClient.get("/company/short-list", params);
  return res;
};

export const onGetCollections = async (filter: any) => {
  const res = await axiosClient.get("/collection/filter-collections", filter);
  return res;
};

export const collectionByUser = async () => {
  const res = await axiosClient.get("collection/list-bough-by-user");
  return res;
};

export const onGetDataDetail = async (id: string) => {
  const res = await axiosClient.get(`/collection/${id}`);
  return res;
};

export const registerUser = async (params: any) => {
  const res = axiosClient.post("/user/register", params);
  return res;
};

export const verifyTokenUser = async (verifyToken: string) => {
  const res = await axiosClient.post("/user/verify-account", {
    verifyToken: verifyToken,
  });
  return res;
};

export const loginUser = async (data: any) => {
  const res = await axiosClient.post("/user/login", data);
  return res;
};

export const onGetCollectionsCompany = async (id: string, params?: any) => {
  const res = await axiosClient.get(`collection/get-by-company/${id}`, params);
  return res;
};

export const onGetWalletAddress = async (walletAddress?: string) => {
  const res = await axiosClient.post("/user/get-by-wallet-address", {
    walletAddress: walletAddress,
  });
  return res;
};

export const subscribeCollection = async (params: any) => {
  const res = await axiosClient.post("/payment/user-buy-nft", params);
  return res;
};

export const onGetMethod = async () => {
  const res = await axiosClient.get(
    "/payment/stripe/user/list-payment-method",
    { type: "card" }
  );
  return res;
};

export const listFaqCategory = async () => {
  return await axiosClient.get("faq/list-cate");
};

export const getConfig = async () => {
  return await axiosClient.get("config");
};

export const getListPost = async () => {
  return await axiosClient.get("post");
};

export const getListCanOrder = async () => {
  return await axiosClient.get("/order/get-list-can-order");
};

export const makeOrder = async (body: any) => {
  return await axiosClient.post("/order/make-order", body);
};
