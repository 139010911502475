import React from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const FeatureBox = () => (
  <>
    <h2>Here’s how it works</h2>

    <div className="container">
      <div className="row">
        <div className="feature-box f-boxed style-3 col-lg-4 col-md-6 mb-3">
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={100}
            duration={600}
            triggerOnce
          >
            <h4 className="">Create your account</h4>
          </Reveal>
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={0}
            duration={600}
            triggerOnce
          >
            <div className="create-your-account feature-box-img"></div>
          </Reveal>
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={200}
            duration={600}
            triggerOnce
          >
            <p className="">
              All you need to get started is a verified email address.
            </p>
          </Reveal>
        </div>

        <div className="feature-box center-box f-boxed style-3 col-lg-4 col-md-6 mb-3">
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={100}
            duration={600}
            triggerOnce
          >
            <h4 className="">Connect your wallet</h4>
          </Reveal>
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={0}
            duration={600}
            triggerOnce
          >
            <div className="connect-your-wallet feature-box-img"></div>
          </Reveal>
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={200}
            duration={600}
            triggerOnce
          >
            <p className="">Link your MetaMask Wallet to Koopon.</p>
          </Reveal>
        </div>

        <div className="feature-box f-boxed style-3 col-lg-4 col-md-6 mb-3">
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={100}
            duration={600}
            triggerOnce
          >
            <h4 className="">Cash out and save</h4>
          </Reveal>
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={0}
            duration={600}
            triggerOnce
          >
            <div className="cash-out-and-save feature-box-img"></div>
          </Reveal>
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={200}
            duration={600}
            triggerOnce
          >
            <p className="">
              Find the deal of your dreams–you can use your debit or credit card
              to purchase.
            </p>
          </Reveal>
        </div>
      </div>
    </div>
  </>
);
export default FeatureBox;
