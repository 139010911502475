import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { get } from "lodash";
import React, { useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { subscribeCollection } from "../../services/services";
import localStorageHelper, { KeyStorage } from "../../utils/localStorage";
import { convertCase } from "../../utils/utils";

interface Props {
  onCloseModal: () => void;
  collectionId: string;
}

const elStyle = {
  base: {
    fontSize: "16px",
  },
};

const AddPaymentMethod: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>();
  const [saveCard, setSaveCard] = useState<boolean>(false);
  const getSession = localStorageHelper.getObject(KeyStorage.SESSION);

  const [nameCard, setNameCard] = useState<string>("");
  // STRIPE PAYMENT
  const stripe = useStripe();
  const elements = useElements();
  elements?.update({ locale: "en" });

  const handleSubmitPayment = async (event: any) => {
    event.preventDefault();

    if (stripe === null) {
      return;
    }
    if (elements === null) {
      return;
    }

    const element = elements.getElement(CardNumberElement);

    if (element === null) {
      return;
    }

    if (nameCard === "") {
      return setTextError("Please enter card information!");
    }

    if (nameCard.length < 6) {
      return setTextError("Card holder name must have at least 6 characters");
    }

    setLoading(true);

    stripe
      .createPaymentMethod({
        type: "card",
        card: element,
        billing_details: {
          name: nameCard,
        },
      })
      .then(function (result) {
        if (result.error) {
          setLoading(false);
          return setTextError(result.error.message);
        }
        setTextError("");

        const params = {
          collectionId: props.collectionId!,
          amount: 1,
          stripeBody: {
            paymentMethodId: result.paymentMethod?.id,
            customer: get(getSession, "data.paymentMethods[0].info.id"),
          },
          shouldSavePaymentMethod: saveCard || false,
        };
        onPayment(params);
      })
      .catch(() => {
        setLoading(false);
        setTextError("Your card was declined or invalid");
      });
  };

  const onPayment = async (params: any) => {
    setLoading(true);
    try {
      const res = await subscribeCollection(params);
      if (res && res?.status) {
        switch (res.status) {
          case "COLLECTION_NOT_FOUND":
            setLoading(false);
            setTextError("Your card was declined or invalid");
            break;
          case "SUCCESS":
            props.onCloseModal();
            setSaveCard(false);
            break;
          default:
            setLoading(false);
            setTextError(convertCase(res?.status));
            break;
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setTextError("Your card was declined or invalid");
    }
  };

  return (
    <>
      {textError ? (
        <Alert key={"danger"} variant={"danger"} className="mt-4">
          {textError}
        </Alert>
      ) : (
        ""
      )}
      <Row className="payment-method mt-4">
        <Col xs={12} className="mt-3">
          <form onSubmit={handleSubmitPayment}>
            <Row>
              <Col xs={6} className="text-left">
                <Form.Label>Card holder name</Form.Label>
                <div>
                  <input
                    className="border py-2 px-3 border-radius-stripe form-control"
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Card holder name"
                    value={nameCard}
                    onChange={(value) => {
                      setNameCard(value.target.value);
                    }}
                  />
                </div>
              </Col>

              <Col xs={6} className="text-left">
                <Form.Label>Card number</Form.Label>
                <CardNumberElement
                  options={{
                    placeholder: "Card number",
                    style: elStyle,
                    showIcon: true,
                  }}
                  className="border border-radius-stripe form-control re-border"
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={6} className="text-left">
                <Form.Label>CVV</Form.Label>
                <CardCvcElement
                  options={{ placeholder: "CVV", style: elStyle }}
                  className="border border-radius-stripe form-control re-border"
                />
              </Col>

              <Col xs={6} className="text-left">
                <Form.Label>Expiration date</Form.Label>
                <CardExpiryElement
                  options={{ placeholder: "Expiration date", style: elStyle }}
                  className="border border-radius-stripe form-control re-border"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <Form.Check
                  style={{ color: "rgb(105, 2, 192)" }}
                  type="checkbox"
                  label="Securely save this card for my later campaign"
                  onChange={(e: any) => {
                    setSaveCard(e?.target?.checked || false);
                  }}
                />
              </Col>
            </Row>

            <div className="mt-5">
              {loading ? (
                <div className="btn-main pay-now">
                  <span>
                    <span
                      className="spinner-border spinner-border-sm mright-5"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </span>
                </div>
              ) : (
                <Form.Control
                  className="btn-main pay-now"
                  type="submit"
                  value={"Pay Now"}
                />
              )}
            </div>
          </form>
        </Col>
      </Row>
    </>
  );
};

export default AddPaymentMethod;
