import React from "react";

const IOSDownload = () => {
  return (
    <div
      style={{
        background: "#fff",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          height: "150px",
          background: "#6902C0",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottomRightRadius: "30px",
          borderBottomLeftRadius: "30px",
        }}
      >
        <img src="/img/Koopon_Solid.png" width={200} alt="" />
      </div>
      <h2
        style={{
          textAlign: "center",
          margin: "40px 0 0px 0",
          fontWeight: 800,
        }}
      >
        It's Koo!
      </h2>
      <h3 style={{ textAlign: "center", margin: "20px 0 4px 0" }}>
        We are building an app for iOS and Android to be released shortly
      </h3>
      <p style={{ textAlign: "center" }}>
        The apps will have amazingly fast deals brought to you, we'll notify you
        shortly of their release!
      </p>
    </div>
  );
};

export default IOSDownload;
