import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import Header from "../menu/Header";
import Footer from "../components/Footer";
import { Collapse } from "antd";

import { listFaqCategory } from "../../services/services";

const { Panel } = Collapse;

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
`;

const FAQ = () => {
  const [openWallet, setOpenWallet] = useState(false);
  const [data, setData] = useState([]);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  const handleGetListFaqCategory = async () => {
    const res = await listFaqCategory();
    if (res.data && res) {
      setData(res.data);
    }
  };

  useEffect(() => {
    handleGetListFaqCategory();
  }, []);

  return (
    <div>
      <GlobalStyles />
      <Header
        isFullWidthMenu={true}
        openWallet={openWallet}
        setOpenWallet={setOpenWallet}
        openRegisterModal={openRegisterModal}
        setOpenRegisterModal={setOpenRegisterModal}
      />
      <section>
        <div
          className="container mt-5 d-flex flex-column justify-content-center align-items-start"
          style={{ minHeight: "200px", width: "60%" }}
        >
          <h1
            style={{ fontSize: "70px", fontWeight: "800" }}
            className="color-full mb-0"
          >
            Frequently
          </h1>
          <h1
            style={{ fontSize: "70px", fontWeight: "800" }}
            className="color-full mb-0"
          >
            Asked
          </h1>
          <h1
            style={{ fontSize: "70px", fontWeight: "800" }}
            className="color-full mb-0"
          >
            Questions
          </h1>
        </div>
        <div
          className="container mt-5 faq-body"
          style={{ minHeight: "200px", width: "60%" }}
        >
          {data.length > 0 &&
            data.map((item, index) => {
              return (
                <div className="mb-5" key={index}>
                  <div className="title">{item.title}</div>
                  <Collapse bordered={false} accordion expandIconPosition="end">
                    {item.faqs &&
                      item.faqs.length > 0 &&
                      item.faqs.map((faq, i) => {
                        return (
                          <Panel header={faq.title} key={i}>
                            <p>{faq.content}</p>
                          </Panel>
                        );
                      })}
                  </Collapse>
                </div>
              );
            })}
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default FAQ;
