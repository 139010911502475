/* eslint-disable jsx-a11y/alt-text */
import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  onGetCollectionsCompany,
  onGetDataDetail,
} from "../../services/services";
import { convertCase, useQuery } from "../../utils/utils";
import Footer from "../components/Footer";
import ModalAddPayment from "../components/ModalAddPayment";
import NftCard2 from "../components/NftCard2";
import Header from "../menu/Header";
import { HelmetTitleTag } from "../components/HelmetTitleTag";

const limit = 6;

const svgView = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.757 11.1231C22.231 11.7431 22.231 12.5801 21.757 13.1991C20.264 15.1481 16.682 19.1611 12.5 19.1611C8.31801 19.1611 4.73601 15.1481 3.24301 13.1991C3.01239 12.9023 2.88721 12.5371 2.88721 12.1611C2.88721 11.7852 3.01239 11.42 3.24301 11.1231C4.73601 9.17413 8.31801 5.16113 12.5 5.16113C16.682 5.16113 20.264 9.17413 21.757 11.1231V11.1231Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 15.1611C14.1569 15.1611 15.5 13.818 15.5 12.1611C15.5 10.5043 14.1569 9.16113 12.5 9.16113C10.8431 9.16113 9.5 10.5043 9.5 12.1611C9.5 13.818 10.8431 15.1611 12.5 15.1611Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const NewItemDetail = () => {
  const [dataDetail, setDataDetail] = useState(undefined);
  const [dataCompany, setDataCompany] = useState([]);
  const [height, setHeight] = useState(0);
  const [openImage, setOpenImage] = useState(false);

  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState();
  const [total, setTotal] = useState(0);
  const [openWallet, setOpenWallet] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  const navigator = useNavigate();

  const modalRef = useRef(null);

  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [loading]);

  useEffect(() => {
    if (id) {
      getDataDetail(id);
    }
  }, [id]);

  const getDataDetail = async (id) => {
    setLoading(true);
    try {
      const res = await onGetDataDetail(id);
      if (res && !isEmpty(res.data)) {
        setDataDetail(res.data);
        setCompany(get(res.data, "company"));

        if (get(res.data, "company._id")) {
          await onGetDataCompany(get(res.data, "company._id"));
        }
      } else {
        setDataDetail(undefined);
        setCompany(undefined);
      }
      setLoading(false);
    } catch (error) {
      setDataDetail(undefined);
      setCompany(undefined);
      setLoading(false);
    }
  };

  const onGetDataCompany = async (idCompany) => {
    try {
      const res = await onGetCollectionsCompany(idCompany, {
        page: 1,
        limit: limit,
      });
      if (res && !isEmpty(res.data)) {
        const newData = [...get(res, "data.data")].filter((d) => d._id !== id);
        setTotal(get(res, "data.total") || 0);
        setDataCompany(newData);
      } else {
        setDataCompany([]);
        setTotal(0);
      }
    } catch (error) {
      setDataCompany([]);
      setTotal(0);
    }
  };

  const seeMore = () => {
    if (!isEmpty(company)) {
      navigator(
        `/collections?company=${company._id}&company_name=${company.name}`
      );
    }
  };

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };

  const onClickBuyNow = (nft) => {
    modalRef?.current?.showModal(nft);
  };

  return (
    <div>
      <Header
        title={dataDetail?.nftName || dataDetail?.name}
        openWallet={openWallet}
        setOpenWallet={setOpenWallet}
        openRegisterModal={openRegisterModal}
        setOpenRegisterModal={setOpenRegisterModal}
      />

      <section className="container">
        <div className="row">
          <div className="spacer-double"></div>

          <div className="row">
            <div className="col-md-6">
              <div className="nft__item_wrap" style={{ height: `${height}px` }}>
                <div className="expired-date">
                  Expires:{" "}
                  {moment(dataDetail?.expiredDate).format("MM-DD-YYYY")}
                </div>
                <img
                  onClick={() => setOpenImage(true)}
                  onLoad={onImgLoad}
                  className="lazy nft__item_preview cursor-pointer"
                  src={get(dataDetail, "avatar.image")}
                  style={{ width: "100%", height: `${height - 1}px` }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="title-new dFlex-token"
                style={{ wordBreak: "break-word" }}
              >
                <div>{dataDetail?.nftName || dataDetail?.name}</div>
                <div style={{ fontSize: "16px" }}>
                  {svgView} {dataDetail?.view || 0}
                </div>
              </div>
              <div
                className="content-new"
                dangerouslySetInnerHTML={{ __html: dataDetail?.description }}
              />

              <div className="creator-collection">
                <div className="creator">
                  <div className="name">Company</div>
                  <div className="title">{dataDetail?.company?.name}</div>
                </div>
                <div className="collection">
                  <div className="name">Category</div>
                  <div className="title">
                    {dataDetail?.category
                      ? convertCase(dataDetail?.category)
                      : ""}
                  </div>
                </div>
              </div>

              <div className="price">
                {dataDetail
                  ? Number(dataDetail.price) > 0
                    ? `$${dataDetail.price.toFixed(2)}`
                    : "Free"
                  : ""}
              </div>
              {dataDetail && (
                <div
                  className="buy-now-new btn-main"
                  onClick={() => onClickBuyNow(dataDetail)}
                >
                  Buy Now
                </div>
              )}
            </div>
          </div>

          <div className="row mt-60">
            <div className="col-md-12">
              <div className="more-collections">
                <div className="title-more-collections">
                  More From {company?.name}
                </div>
                {/* {total > limit && (
                  <div>
                    <span onClick={seeMore} className="btn-main lead">
                      See More
                    </span>
                  </div>
                )} */}
              </div>
            </div>
          </div>
          <div className="row mt-20 mx-3">
            {!isEmpty(dataCompany) ? (
              <>
                {dataCompany.map((nft, index) => (
                  <NftCard2
                    nft={nft}
                    key={index}
                    height={300}
                    className="d-item col-lg-2 col-md-3 col-sm-6 col-xs-12 mb-4"
                    collections={true}
                    onClickBuyNow={() => onClickBuyNow(nft?._id)}
                  />
                ))}
              </>
            ) : (
              <div className="col-md-12">No Data</div>
            )}
          </div>
        </div>
      </section>
      <Footer />

      {openImage && (
        <div className="checkout">
          <div
            className="maincheckout main-img"
            style={{ height: `${height}px` }}
          >
            <button className="btn-close" onClick={() => setOpenImage(false)}>
              x
            </button>
            <div className="mt-60">
              <img
                onLoad={onImgLoad}
                className="lazy nft__item_preview"
                src={get(dataDetail, "avatar.image")}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      )}

      {/* Modal Add Payment */}
      <ModalAddPayment ref={modalRef} />
    </div>
  );
};

export default NewItemDetail;
