import React, { useEffect } from "react";
import Reveal from "react-awesome-reveal";
// import Lottie from "lottie-react";
// import lottie from "lottie-web";
import { keyframes } from "@emotion/react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import localStorageHelper, { KeyStorage } from "../../utils/localStorage";
// import homeSlideAnimation from "../../assets/slide-animation.json";

import "@dotlottie/player-component";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

const SliderHome = ({ onClickToOpenRegisterModal }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const getSession = localStorageHelper.getObject(KeyStorage.SESSION);

  const onSubmit = handleSubmit(async (data) => {
    // if (!data || !data.searchKoopon.trim()) return;
    const params = new URLSearchParams({ search: data.searchKoopon });
    navigate({
      pathname: "/products",
      search: `?${params.toString()}`,
    });
  });

  const handleClickOnStarted = () => {
    const data = getSession.data;
    if (data) {
      navigate("/products");
    } else {
      onClickToOpenRegisterModal();
    }
  };

  // useEffect(() => {
  //   lottie.loadAnimation({
  //     container: document.querySelector("#el-animation"),
  //     renderer: "svg",
  //     loop: true,
  //     autoplay: true,
  //     animationData: homeSlideAnimation,
  //     rendererSettings: {
  //       className: "el-animation",
  //     },
  //   });
  //   lottie.setQuality("low");
  //   // lottie.freeze();
  // }, []);

  return (
    <div className="row align-items-center">
      <div
        style={{
          height: "450px",
          position: "relative",
          display: "block",
          marginTop: "10px",
        }}
      >
        {/* <div
          id="el-animation"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        ></div> */}
        <dotlottie-player
          autoplay
          loop
          src="/Ben_Fisher_WebSite_animation.lottie"
          style={{ height: "100%", width: "100%" }}
        ></dotlottie-player>
        <div
          className="col-md-12 text-center"
          style={{ position: "absolute", top: "0", zIndex: "99" }}
        >
          <div className="spacer-single"></div>
          <div className="spacer-single"></div>
          <div className="spacer-single"></div>
          <div className="spacer-single"></div>
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={300}
            duration={900}
            triggerOnce
          >
            <h1 className="jumbotron-text" style={{ color: "#fff" }}>
              The Deal Revolution
            </h1>
          </Reveal>
          <div className="spacer-10"></div>
          <Reveal
            className="onStep d-inline"
            keyframes={inline}
            delay={800}
            duration={900}
            triggerOnce
          >
            <Form onSubmit={onSubmit} className="search-form">
              <i className="fa fa-search" aria-hidden="true"></i>
              <Form.Control
                {...register("searchKoopon")}
                placeholder="Search Koopon (e.g. soap, laptops, etc)"
              />

              <Form.Control
                className="btn-main-search"
                type="submit"
                value="Search"
              />
            </Form>
            <div className="mb-sm-30"></div>
          </Reveal>
        </div>
      </div>

      <Reveal
        className="onStep d-inline"
        keyframes={inline}
        delay={800}
        duration={900}
        triggerOnce
      >
        <div className="jumbotron-desc">
          <p className="mb-0">
            Stop being ripped off. Introducing the new way to shop.
          </p>
          <p className="mb-5">
            Get comfortable savings on all your favorite brands with Koopon.
          </p>
          <div className="jumbotron-desc-button">
            <button onClick={handleClickOnStarted} className="btn">
              Get started
            </button>
            {/* {!hideConnectButtonOnJumbotron && (
              <button onClick={onConnectWallet} className="btn-main-search">
                Connect Wallet
              </button>
            )} */}
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default SliderHome;
