import React, { useRef } from "react";
import ModalCheckout from "./ModalCheckout";

const OrderButton = () => {
  const modalRef = useRef(null);

  const handleClickOrder = () => {
    modalRef?.current?.showModal();
  };

  return (
    <>
      <div className="order-button" onClick={handleClickOrder}>
        <img src="/favicon.png" width={28} height={28} alt="logo" />
        <span>Checkout</span>
      </div>
      <ModalCheckout ref={modalRef} />
    </>
  );
};

export default OrderButton;
