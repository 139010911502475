import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import Header from '../menu/Header';
import Footer from '../components/Footer';

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
`;
const BrandsUp = () => {
	const [openWallet, setOpenWallet] = useState(false);
	const [openRegisterModal, setOpenRegisterModal] = useState(false);
	return (
		<div>
			<GlobalStyles />
			<Header
				isFullWidthMenu={true}
				openWallet={openWallet}
				setOpenWallet={setOpenWallet}
				openRegisterModal={openRegisterModal}
				setOpenRegisterModal={setOpenRegisterModal}
			/>
			<section>
				<div
					className="container mt-5 d-flex flex-column justify-content-center align-items-start"
					style={{ minHeight: '200px', width: '60%' }}
				>
					<h1
						style={{ fontSize: '70px', fontWeight: '800' }}
						className="color-full mb-0"
					>
						Koopon
					</h1>
					<h1
						style={{ fontSize: '70px', fontWeight: '800' }}
						className="color-full mb-0"
					>
						Partner Brands
					</h1>
					<h1
						style={{ fontSize: '70px', fontWeight: '800' }}
						className="color-full mb-0"
					>
						Supplemental Terms
					</h1>
				</div>
				<div
					className="container mt-5 faq-body"
					style={{ minHeight: '200px', width: '60%' }}
				>
					<p style={{ fontSize: '18px', fontWeight: 'bold' }}>
						Last Updated: 2/6/2023
					</p>
					<p>
						Please read these Partner Brands Supplemental Terms (the
						“Supplemental Terms”) carefully. Your use of the Site as
						a Partner Brand constitutes your consent to the Koopon
						Terms of Service (the “Terms of Service”) located at{' '}
						<a
							style={{ textDecoration: 'underline' }}
							href="https://www.koopon.xyz/tos"
						>
							https://www.koopon.xyz/tos
						</a>{' '}
						and these Supplemental Terms. Any capitalized terms
						used, but not defined, in these Supplemental Terms have
						the same meanings as defined in the Terms of Service. In
						the event there is any conflict or inconsistency between
						the Supplemental Terms and the Terms of the Service, the
						Supplemental Terms take precedence with respect to or
						use of the site as a Partner Brand and the subject
						matter of the Supplemental Terms.
					</p>
					<p style={{ textTransform: 'uppercase' }}>
						By using the Site, you affirm that you are of legal age
						to enter into these Supplemental Terms.
					</p>
					<p>
						<span style={{ textTransform: 'uppercase' }}>
							If you are an individual accessing or using the Site
							on behalf of, or for the benefit of, any
							corporation, partnership or other entity with which
							you are associated (an “Organization”), then you
							agree to these Supplemental Terms on behalf of
							yourself and such Organization, and you represent
							and warrant that you have the legal authority to
							bind such Organization to these Supplemental Terms.
						</span>{' '}
						<span>
							References to “you” and “your” in these Supplemental
							Terms will refer to both the individual using the
							Site and to any such Organization.
						</span>
					</p>
					<p style={{ textTransform: 'uppercase' }}>
						These Supplemental Terms contain a mandatory arbitration
						provision that, as further set forth in Section 6 below,
						requires the use of arbitration on an individual basis
						to resolve disputes, rather than jury trials or any
						other court proceedings, or class actions of any kind.
					</p>
					<p>
						<b>1.&nbsp;&nbsp;&nbsp;&nbsp;Changes.</b>{' '}
						<span>
							We may change these Supplemental Terms from time to
							time by notifying you of such changes by any
							reasonable means, including by posting revised
							Supplemental Terms through the Site. Any such
							changes will not apply to any dispute between you
							and us arising prior to the date on which we posted
							the revised Supplemental Terms incorporating such
							changes, or otherwise notified you of such changes.
						</span>
						<br />
						<br />
						<span>
							Your use of the Site following any changes to these
							Supplemental Terms will constitute your acceptance
							of such changes. The “<i>Last Updated</i>” legend
							above indicates when these Supplemental Terms were
							last changed. We may, at any time and without
							liability, modify or discontinue all or part of the
							Site (including access to the Site via any
							third-party links); charge, modify or waive any fees
							required to use the Site; or offer opportunities to
							some or all Site users.
						</span>
					</p>
					<p>
						<b>2.&nbsp;&nbsp;&nbsp;&nbsp;Product Listings.</b>{' '}
						<span>
							The Site provides a platform through which you or
							Company may make available listings, descriptions
							and images of Products. For clarity, any such
							listings, descriptions and images of Products you
							make available through the Site are considered
							Submissions under the Terms of Service. You agree
							that Company may make available through the Site any
							valid coupons and discounts from any source for any
							of your Products.{' '}
						</span>
						<br />
						<br />
						<span>
							We reserve the right to reject any coupons or gift
							cards that we believe (in our sole discretion) may:
							(i) be fraudulent, invalid, inauthentic or stolen,
							(ii) have come from an unauthorized or illegal
							source, (iii) be related to any illegal activity or
							(iv) otherwise pose a legal, financial, or
							reputational risk to us, our customers, or our Brand
							Partners. We are not responsible for any gift card
							or coupons listed on the Site and we reserve the
							right to refuse to process or sell any gift card on
							the Site and may cancel any Transaction in our
							discretion.
						</span>
					</p>
					<p>
						<b>3.&nbsp;&nbsp;&nbsp;&nbsp;Transactions.</b>{' '}
						<span>
							<span>
								Site users may be able to purchase or otherwise
								obtain your Products through the Site (a
								“Transaction”). Please see our FAQ page located
								at{' '}
								<a
									style={{ textDecoration: 'underline' }}
									href="https://www.koopon.xyz/faq"
								>
									https://www.koopon.xyz/faq
								</a>{' '}
								for payment and pricing information for our
								services.
							</span>{' '}
							<span style={{ textTransform: 'uppercase' }}>
								Brand Partners agree that they are solely
								responsible for delivering, shipping, or
								otherwise providing access to any of the
								Products that users purchase through the Site.
							</span>
							{'  '}
							<span>
								Records of users’ Transactions will be stored in
								users’ respective crypto wallets, through which
								Brand Partners may verify the Transactions. No
								Transaction is complete until we have received
								all necessary verification information.
							</span>
						</span>
					</p>
					<p>
						<b style={{ textTransform: 'uppercase' }}>
							4.&nbsp;&nbsp;&nbsp;&nbsp;Limitation of Liability.
						</b>{' '}
						<span style={{ textTransform: 'uppercase' }}>
							To the fullest extent permitted under applicable
							law: (a) Company will not be liable for any
							indirect, incidental, consequential, special,
							exemplary or punitive damages of any kind, under any
							contract, tort (including negligence), strict
							liability or other theory, including damages for
							loss of profits, use or data, loss of other
							intangibles, loss of security of Submissions
							(including unauthorized interception by third
							parties of any Submissions), even if advised in
							advance of the possibility of such damages or
							losses; (b) without limiting the foregoing, Company
							will not be liable for damages of any kind resulting
							from your use of or inability to use the Site,
							Submissions, any Products or Third Party Materials,
							including from any Virus that may be transmitted in
							connection therewith; (c) your sole and exclusive
							remedy for dissatisfaction with the Site,
							Submissions, any Products or Third Party Materials
							is to stop using the Site; and (d) the maximum
							aggregate liability of Company for all damages,
							losses and causes of action, whether in contract,
							tort (including negligence) or otherwise, will be
							the greater of (I) fifty per-cent (50%) of the total
							amount, if any, paid by you to Company in the twelve
							(12) months preceding the initial incident giving
							rise to the claim or (ii) $1000. All limitations of
							liability of any kind (including in this section and
							elsewhere in these Supplemental Terms and in the
							Terms of Service) are made for the benefit of both
							Company and the Affiliated Entities, and their
							respective successors and assigns.
						</span>
					</p>
					<p>
						<b>5.&nbsp;&nbsp;&nbsp;&nbsp;Indemnity.</b>{' '}
						<span>
							Without limiting the Koopon Terms of Service Section
							17, to the fullest extent permitted under applicable
							law, you agree to defend, indemnify and hold
							harmless Company and the Affiliated Entities, and
							their respective successors and assigns, from and
							against all claims, liabilities, damages, judgments,
							awards, losses, costs, expenses and fees (including
							attorneys’ fees) arising out of or relating to the
							Products.
						</span>
					</p>
					<p>
						<b>6.&nbsp;&nbsp;&nbsp;&nbsp;Termination.</b>{' '}
						<span>
							These Supplemental Terms are effective until
							terminated. Company may terminate or suspend your
							use of the Site at any time and without prior
							notice, for any or no reason, including if Company
							believes that you have violated or acted
							inconsistently with the letter or spirit of these
							Supplemental Terms. Upon any such termination or
							suspension, your right to use the Site will
							immediately cease, and Company may, without
							liability to you or any third party, immediately
							deactivate or delete your user name, password and
							account, and all associated materials, without any
							obligation to provide any further access to such
							materials. Sections 2 and 4–8 shall survive any
							expiration or termination of these Supplemental
							Terms.
						</span>
					</p>
					<p>
						<b>
							7.&nbsp;&nbsp;&nbsp;&nbsp;Governing Law;
							Arbitration.
						</b>{' '}
						<span>
							<span>
								These Supplemental Terms are governed by the
								laws of the United States (including federal
								arbitration law) and the State of New York,
								U.S.A., without regard to its principles of
								conflicts of law, and regardless of your
								location.
							</span>{' '}
							<span style={{ textTransform: 'uppercase' }}>
								ALL DISPUTES ARISING OUT OF OR RELATED TO THESE
								SUPPLEMENTAL TERMS, YOUR USE OF THE PLATFORM AS
								A PARTNER BRAND, OR ANY ASPECT OF THE
								RELATIONSHIP BETWEEN YOU AND COMPANY, WHETHER
								BASED IN CONTRACT, TORT, STATUTE, FRAUD,
								MISREPRESENTATION OR ANY OTHER LEGAL THEORY,
								WILL BE RESOLVED THROUGH FINAL AND BINDING
								ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD
								OF IN A COURT BY A JUDGE OR JURY, AND YOU AGREE
								THAT COMPANY AND YOU ARE EACH WAIVING THE RIGHT
								TO TRIAL BY A JURY. YOU AGREE THAT ANY
								ARBITRATION UNDER THESE SUPPLEMENTAL TERMS WILL
								TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS
								ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED
								AND YOU ARE AGREEING TO GIVE UP THE ABILITY TO
								PARTICIPATE IN A CLASS ACTION.
							</span>{' '}
							<span>
								The arbitration will be administered by the
								American Arbitration Association under its
								Commercial Arbitration Rules, as amended by
								these Supplemental Terms. The Commercial
								Arbitration Rules are available online at{' '}
								<a
									style={{ textDecoration: 'underline' }}
									href="https://www.adr.org/sites/default/files/Consumer_Rules_Web_2.pdf"
								>
									https://www.adr.org/sites/default/files/Consumer_Rules_Web_2.pdf
								</a>
								. Any in-person appearances will be held in
								Manhattan, New York, U.S.A. The arbitrator’s
								decision will follow the terms of these
								Supplemental Terms and will be final and
								binding. The arbitrator will have authority to
								award temporary, interim or permanent injunctive
								relief or relief providing for specific
								performance of these Supplemental Terms, but
								only to the extent necessary to provide relief
								warranted by the individual claim before the
								arbitrator. The award rendered by the arbitrator
								may be confirmed and enforced in any court
								having jurisdiction thereof.
							</span>
						</span>
					</p>
					<p>
						<b>8.&nbsp;&nbsp;&nbsp;&nbsp;Miscellaneous.</b>{' '}
						<span>
							These Supplemental Terms do not, and shall not be
							construed to, create any partnership, joint venture,
							employer-employee, agency or franchisor-franchisee
							relationship between you and Company. If any
							provision of these Supplemental Terms is found to be
							unlawful, void or for any reason unenforceable, that
							provision will be deemed severable from these
							Supplemental Terms and will not affect the validity
							and enforceability of any remaining provision. You
							may not assign, transfer or sublicense any or all of
							your rights or obligations under these Supplemental
							Terms without our express prior written consent. We
							may assign, transfer or sublicense any or all of our
							rights or obligations under these Supplemental Terms
							without restriction. No waiver by either party of
							any breach or default under these Supplemental Terms
							will be deemed to be a waiver of any preceding or
							subsequent breach or default. We may seek any and
							all other forms of recourse against any Partner
							Brand that we determine has sold invalid,
							fraudulent, inaccurate or illegally obtained
							coupons, gift cards, or discounts on our Site or any
							Partner Brand who in our determination was involved
							in any illegal or fraudulent activity in connection
							with its sale of coupons, gift cards, or discounts
							on the Site. Any heading, caption or section title
							contained herein is for convenience only, and in no
							way defines or explains any section or provision.
							All terms defined in the singular shall have the
							same meanings when used in the plural, where
							appropriate and unless otherwise specified. Any use
							of the term “including” or variations thereof in
							these Supplemental Terms shall be construed as if
							followed by the phrase “without limitation.” These
							Supplemental Terms, including any terms and
							conditions incorporated herein, is the entire
							agreement between you and Company relating to the
							subject matter hereof, and supersedes any and all
							prior or contemporaneous written or oral agreements
							or understandings between you and Company relating
							to such subject matter. Notices to you (including
							notices of changes to these Supplemental Terms) may
							be made via posting to the Site or by e-mail
							(including in each case via links), or by regular
							mail. Without limitation, a printed version of these
							Supplemental Terms and of any notice given in
							electronic form shall be admissible in judicial or
							administrative proceedings based upon or relating to
							these Supplemental Terms to the same extent and
							subject to the same conditions as other business
							documents and records originally generated and
							maintained in printed form. Company will not be
							responsible for any failure to fulfill any
							obligation due to any cause beyond its control.
						</span>
					</p>
					<p>
						<b>9.&nbsp;&nbsp;&nbsp;&nbsp;Shopify-Specific Terms.</b>{' '}
						<span>
							In addition to your agreement with the foregoing
							terms and conditions, and notwithstanding anything
							to the contrary herein, the following provisions
							apply with respect to your use of any version of the
							Site made available in connection with Shopify Inc.
							(“Shopify”) via the Shopify App Store or a URL (the
							“App”). Shopify is not a party to this Agreement and
							does not own and is not responsible for the App. To
							the maximum extent permitted by applicable law,
							Shopify will have no warranty obligation whatsoever
							with respect to the App and Shopify is not liable
							for any fault in the App or any harm that may result
							from its installation or use. Except where expressly
							stated by Shopify, Shopify cannot provide assistance
							with the installation or use of the App. As between
							Shopify and Company, Company is solely responsible
							for any liability which may arise from a your access
							to or use of the App, including: (A) the
							development, use, marketing or distribution of or
							access to the App, including support of the App; and
							(B) Company’s access, use, distribution or storage
							of Merchant Data.
						</span>
					</p>
					<p>
						Site © 2023 Koopon Inc., unless otherwise noted. All
						rights reserved.
					</p>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default BrandsUp;
