import { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { registerUser } from "../../services/services";
import { convertCase } from "../../utils/utils";
import { get } from "lodash";
import localStorageHelper, { KeyStorage } from "../../utils/localStorage";
import { CloseIcon } from "./ICons";

const ModalRegister = (props) => {
  const { open, setOpen } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [openRegisterSuccess, setOpenRegisterSuccess] = useState(false);
  const [textError, setTextError] = useState("");
  const [mailTo, setMailTo] = useState();

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const params = { ...data };
      const res = await registerUser(params);
      if (res && res?.status) {
        switch (res.status) {
          case "VALIDATION_ERROR":
            setTextError(convertCase(get(res, "message")));
            break;
          case "SUCCESS":
            reset();
            setTextError(undefined);
            setOpen(false);
            // Show Modal Register Success
            setOpenRegisterSuccess(true);
            // Set Email
            setMailTo(params.email);
            // Save LocalStorage
            localStorageHelper.setObject(KeyStorage.USER_REGISTER, res?.data);
            break;
          default:
            setTextError(convertCase(res?.status));
            break;
        }
      }
      setLoading(false);
    } catch (error) {
      setTextError(error?.message);
      setLoading(false);
    }
  });

  const closeVerifyEmail = () => {
    setOpenRegisterSuccess(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    reset();
    setTextError("");
  };

  return (
    <>
      {open && (
        <div className="checkout">
          <div className="maincheckout">
            <button className="btn-close" onClick={handleCloseModal}>
              <CloseIcon width={20} height={20} />
            </button>
            <div className="mt-30">
              {textError ? (
                <Alert key={"danger"} variant={"danger"}>
                  {textError}
                </Alert>
              ) : (
                ""
              )}
              {!isEmpty(errors) ? (
                <Alert key={"danger"} variant={"danger"}>
                  {Object.values(errors).map((d, k) => {
                    return (
                      <div key={`errors-${k + 1}`} className="mb-2">
                        {d?.message}
                      </div>
                    );
                  })}
                </Alert>
              ) : (
                ""
              )}
              <div className="title-register color-full">
                You're almost there
              </div>
              <div className="content-register">
                Enter your full name and enter your email address to begin.
              </div>
              <div className="form-login">
                <Form onSubmit={onSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      {...register("name", {
                        required: {
                          value: true,
                          message: "You must specify a full name",
                        },
                        minLength: {
                          value: 2,
                          message: "Full name must have at least 2 characters",
                        },
                      })}
                      placeholder="Full Name"
                      autoComplete="off"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control
                      {...register("email", {
                        required: {
                          value: true,
                          message: "You must specify a email",
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                      })}
                      placeholder="Enter Your Email"
                      type="email"
                      autoComplete="off"
                    />
                  </Form.Group>

                  <div>
                    {loading ? (
                      <Button
                        className="btn-main-sign-up"
                        type="submit"
                        disabled={true}
                      >
                        <span>
                          <span
                            className="spinner-border spinner-border-sm mright-5"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </span>
                      </Button>
                    ) : (
                      <Form.Control
                        className="btn-main-sign-up"
                        type="submit"
                        value={"Submit"}
                      />
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}

      {openRegisterSuccess && (
        <div className="checkout">
          <div className="maincheckout">
            <button
              className="btn-close"
              onClick={() => setOpenRegisterSuccess(false)}
            >
              <CloseIcon width={20} height={20} />
            </button>
            <div className="mt-30">
              <div className="title-register">Verify your Email</div>
              <div className="content-register padding-20">
                We've sent a confirmation email to <i>{mailTo}</i>.
                <br />
                Please verify your email address.
              </div>
              <div className="form-login">
                <div
                  className="btn-main-sign-up go-to-mail"
                  onClick={closeVerifyEmail}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalRegister;
