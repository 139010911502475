// import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import React, { memo, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { onGetNewCollections } from "../../services/services";
import ModalAddPayment from "./ModalAddPayment";
import NftCard2 from "./NftCard2";
import localStorageHelper, { KeyStorage } from "../../utils/localStorage";

const NewItems = ({ onClickToOpenRegisterModal }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const modalRef = useRef(null);
  // const { isLoading, data } = useQuery(
  //   ["hot-new-collections"],
  //   onGetNewCollections
  // );

  const handleGetNewCollections = async () => {
    setLoading(true);
    const res = await onGetNewCollections();
    if (res && res.data.length > 0) {
      setData(res.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetNewCollections();
  }, []);

  const onClickBuyNow = (nft) => {
    modalRef?.current?.showModal(nft);
  };
  const getSession = localStorageHelper.getObject(KeyStorage.SESSION);

  const handleClickOnStarted = () => {
    const data = getSession.data;
    if (data) {
      navigate("/products");
    } else {
      onClickToOpenRegisterModal();
    }
  };

  return (
    <>
      {loading ? (
        <div className="text-center">
          <div className="spinner-border" />
        </div>
      ) : !isEmpty(data) ? (
        <>
          <div className="col-md-8 row">
            {data.slice(0, 6).map((nft, index) => (
              <div
                className={`col-lg-4 col-md-4 col-sm-6 px-2 ${
                  index <= 2 ? "mb-3" : ""
                }`}
                index={index + 1}
                key={index}
              >
                <NftCard2
                  nft={nft}
                  key={index}
                  height={179}
                  onClickBuyNow={() => onClickBuyNow(nft)}
                />
              </div>
            ))}
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center new-products-right">
            <h1>The Deal Revolution.</h1>
            <span onClick={handleClickOnStarted}>Get Started</span>
          </div>
        </>
      ) : (
        <div className="text-center">No Data</div>
      )}
      {/* Modal Add Payment */}
      <ModalAddPayment ref={modalRef} />
    </>
  );
};

export default memo(NewItems);
