import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  onGetCategory,
  onGetCollections,
  onGetCompany,
  onGetHotCollections,
} from "../../services/services";
import NftCard2 from "./NftCard2";
import { isEmpty } from "lodash";
import { useQuery, truncateString } from "../../utils/utils";
import { useDebounceFn } from "ahooks";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import ModalAddPayment from "./ModalAddPayment";
import DataPagination from "../components/DataPagination";

const svgLeft = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7074 7.29321C14.5199 7.10574 14.2656 7.00043 14.0004 7.00043C13.7352 7.00043 13.4809 7.10574 13.2934 7.29321L10.0004 10.5862L6.70741 7.29321C6.5188 7.11106 6.2662 7.01026 6.00401 7.01254C5.74181 7.01482 5.491 7.11999 5.30559 7.3054C5.12018 7.4908 5.01501 7.74162 5.01273 8.00381C5.01045 8.26601 5.11125 8.51861 5.29341 8.70721L9.29341 12.7072C9.48094 12.8947 9.73524 13 10.0004 13C10.2656 13 10.5199 12.8947 10.7074 12.7072L14.7074 8.70721C14.8949 8.51969 15.0002 8.26538 15.0002 8.00021C15.0002 7.73505 14.8949 7.48074 14.7074 7.29321Z"
      fill="#7801DB"
    />
  </svg>
);

const svgRight = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7069 12.7068C14.5194 12.8943 14.2651 12.9996 13.9999 12.9996C13.7348 12.9996 13.4804 12.8943 13.2929 12.7068L9.99992 9.41379L6.70692 12.7068C6.51832 12.8889 6.26571 12.9897 6.00352 12.9875C5.74132 12.9852 5.49051 12.88 5.3051 12.6946C5.11969 12.5092 5.01452 12.2584 5.01224 11.9962C5.00997 11.734 5.11076 11.4814 5.29292 11.2928L9.29292 7.29279C9.48045 7.10532 9.73475 7 9.99992 7C10.2651 7 10.5194 7.10532 10.7069 7.29279L14.7069 11.2928C14.8944 11.4803 14.9997 11.7346 14.9997 11.9998C14.9997 12.265 14.8944 12.5193 14.7069 12.7068Z"
      fill="#7801DB"
    />
  </svg>
);

const ItemCollections = ({ filter, setFilter }) => {
  // Hooks Delete Params
  const [searchParams, setSearchParams] = useSearchParams();
  // Open Menu Filter
  const [open, setOpen] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [openCompany, setOpenCompany] = useState(false);

  const [filterCate, setFilterCate] = useState([]);
  const [filterCompany, setFilterCompany] = useState([]);
  const [filterCompanyHeader, setFilterCompanyHeader] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const [dataCategories, setDataCategories] = useState([]);
  const [dataCompany, setDataCompany] = useState([]);

  const query = useQuery();
  const queryCategory = query.get("category");
  const queryCompany = query.get("company");
  const queryCompanyName = query.get("company_name");

  const [dataCollections, setDataCollections] = useState([]);

  const [loadingCate, setLoadingCate] = useState(false);

  const modalRef = useRef(null);

  const [hotCollections, setHotCollections] = useState([]);

  const handleGetHotCollections = async () => {
    const res = await onGetHotCollections();
    if (res && res.data.length > 0 && res.status === "SUCCESS") {
      setHotCollections(res.data);
    }
  };

  useEffect(() => {
    if (filter) {
      const params = { ...filter };
      if (queryCategory) {
        params.categories = [String(queryCategory)];
        setFilterCate([String(queryCategory)]);
        setOpen(true);
      }
      if (queryCompany) {
        params.companyIds = [String(queryCompany)];
        setFilterCompany([String(queryCompany)]);
        setFilterCompanyHeader([
          {
            _id: String(queryCompany),
            name: String(queryCompanyName),
          },
        ]);
        setOpenCompany(true);
      }

      getDataCollections(params);
    }
  }, [filter, queryCategory]);

  useEffect(() => {
    getCategories();
    getCompany();
    handleGetHotCollections();
  }, []);

  const getCategories = async () => {
    try {
      const res = await onGetCategory();
      if (res && res.data) {
        setDataCategories(res.data);
      } else {
        setDataCategories([]);
      }
    } catch (error) {
      setDataCategories([]);
    }
  };

  const getCompany = async (search) => {
    try {
      const res = await onGetCompany({
        limit: 10,
        page: 1,
        search: search,
      });
      if (res && res.data) {
        setDataCompany(res.data);
      } else {
        setDataCompany([]);
      }
    } catch (error) {
      setDataCompany([]);
    }
  };

  const onChangeCategory = (event) => {
    if (searchParams.has("category")) {
      searchParams.delete("category");
      setSearchParams(searchParams);
    }

    var uploadCate = [...filterCate];
    if (event.target.checked) {
      uploadCate = [...filterCate, event.target.value];
    } else {
      uploadCate.splice(filterCate.indexOf(event.target.value), 1);
    }
    setFilterCate(uploadCate);
    setFilter({
      ...filter,
      categories: !isEmpty(uploadCate) ? uploadCate : undefined,
    });
  };

  const onChangeCompany = (event, item) => {
    if (searchParams.has("company")) {
      searchParams.delete("company");
      setSearchParams(searchParams);
    }
    if (searchParams.has("company_name")) {
      searchParams.delete("company_name");
      setSearchParams(searchParams);
    }
    let uploadCompany = [...filterCompany];
    let dataFilterHeader = [...filterCompanyHeader];
    if (event.target.checked) {
      uploadCompany = [...filterCompany, event.target.value];
      dataFilterHeader = [...filterCompanyHeader, item];
    } else {
      uploadCompany.splice(filterCompany.indexOf(event.target.value), 1);
      dataFilterHeader = filterCompanyHeader.filter(
        (d) => d._id !== event.target.value
      );
    }
    setFilterCompany(uploadCompany);
    setFilterCompanyHeader(dataFilterHeader);
    setFilter({
      ...filter,
      companyIds: !isEmpty(uploadCompany) ? uploadCompany : undefined,
    });
  };

  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();

  const { run } = useDebounceFn(
    (data, type) => {
      if (type === "min") {
        setMinPrice(data || undefined);
      } else if (type === "company") {
        getCompany(data);
      } else {
        setMaxPrice(data || undefined);
      }
    },
    { wait: 500 }
  );

  const [showPrice, setShowPrice] = useState(false);
  const applyPrice = () => {
    if (minPrice && minPrice) {
      setShowPrice(true);
      setFilter({
        ...filter,
        minPrice: minPrice,
        maxPrice: maxPrice,
      });
    }
  };

  const closePrice = () => {
    setShowPrice(false);
    setFilter({
      ...filter,
      minPrice: undefined,
      maxPrice: undefined,
    });
  };

  const closeCategory = async (data) => {
    if (searchParams.has("category")) {
      searchParams.delete("category");
      setSearchParams(searchParams);
    }

    setLoadingCate(true);
    const newDataCategoty = await filterCate.filter((d) => d !== data);

    setFilterCate(newDataCategoty);
    setFilter({
      ...filter,
      categories: isEmpty(newDataCategoty) ? undefined : newDataCategoty,
    });
    setLoadingCate(false);
  };

  const [loadingCompany, setLoadingCompany] = useState(false);

  const closeCompany = async (data) => {
    if (searchParams.has("company")) {
      searchParams.delete("company");
      setSearchParams(searchParams);
    }
    if (searchParams.has("company_name")) {
      searchParams.delete("company_name");
      setSearchParams(searchParams);
    }

    setLoadingCompany(true);
    const newDataCompany = await filterCompany.filter((d) => d !== data);
    const newDataCompanyHeader = await filterCompanyHeader.filter(
      (d) => d._id !== data
    );

    setFilterCompany(newDataCompany);
    setFilterCompanyHeader(newDataCompanyHeader);
    setFilter({
      ...filter,
      companyIds: isEmpty(newDataCompany) ? undefined : newDataCompany,
    });
    setLoadingCompany(false);
  };

  const getDataCollections = async (filter) => {
    setLoading(true);
    try {
      const res = await onGetCollections(filter);
      if (res && res?.status === "SUCCESS") {
        setDataCollections(res?.data);
        setTotal(res?.total);
      } else {
        setDataCollections(res?.data);
        setTotal(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onChangePage = (number) => {
    setFilter({ ...filter, page: number });
  };

  const renderPagination = (total) => {
    return (
      <DataPagination
        total={total}
        itemsPerPage={9}
        onChangePage={onChangePage}
      />
    );
  };

  const renderCheckBox = (item) => {
    let checked = false;
    if (filterCate.includes(item)) {
      checked = true;
    }
    return checked;
  };

  const renderCheckBoxCompany = (item) => {
    let checked = false;
    if (filterCompany.includes(item)) {
      checked = true;
    }
    return checked;
  };

  const customStyles = {
    option: (base, state) => ({
      ...base,
      background: "#fff",
      color: "#7801DB",
      borderRadius: state.isFocused ? "0" : 0,
      "&:hover": {
        background: "#eee",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      zIndex: 3,
      color: "#7801DB",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      color: "#7801DB",
    }),
    control: (base, state) => ({
      ...base,
      padding: 2,
      borderRadius: 16,
      borderColor: state.isFocused ? "#7801DB" : "#7801DB",
      color: "#7801DB",
      cursor: state.isHovered ? "#7801DB" : "#7801DB",
      isHovered: "#7801DB",
      boxShadow: "0 0 0 1px #9c27b0",
      "&:hover": {
        borderColor: "#7801DB",
      },
    }),
    singleValue: () => ({
      color: "#7801DB",
    }),
  };

  const priceSelect = [
    { value: "ASC", label: "Price low to high" },
    { value: "DESC", label: "Price high to low" },
    { value: "RECENTLY", label: "Recently listed" },
    { value: "CREATED_AT", label: "Oldest" },
  ];

  const [selectPrice, setSelectPrice] = useState(undefined);

  const onChangeSelect = useCallback(
    (option) => {
      const { value } = option;
      setSelectPrice(value);
    },
    [selectPrice]
  );

  useEffect(() => {
    if (selectPrice) {
      let params = {
        sortByDir: undefined,
        sortByDirection: undefined,
      };
      switch (selectPrice) {
        case "ASC":
          params = { sortByDir: "PRICE", sortByDirection: "ASC" };
          break;
        case "DESC":
          params = { sortByDir: "PRICE", sortByDirection: "DESC" };
          break;
        case "CREATED_AT":
          params = { sortByDir: "CREATED_AT", sortByDirection: "ASC" };
          break;
        default:
          params = {
            sortByDir: undefined,
            sortByDirection: undefined,
          };
          break;
      }
      setFilter({
        ...filter,
        ...params,
      });
    }
  }, [selectPrice]);

  const clearAll = async () => {
    if (searchParams.has("category")) {
      searchParams.delete("category");
      setSearchParams(searchParams);
    }
    if (searchParams.has("company")) {
      searchParams.delete("company");
      setSearchParams(searchParams);
    }
    if (searchParams.has("company_name")) {
      searchParams.delete("company_name");
      setSearchParams(searchParams);
    }

    setLoadingCompany(true);
    setLoadingCate(true);

    setFilterCate([]);
    setFilterCompany([]);
    setFilterCompanyHeader([]);
    setShowPrice(false);
    setMinPrice(undefined);
    setMaxPrice(undefined);
    setFilter({ ...filter, categories: undefined });
    await getCompany();
    setTimeout(() => {
      setLoadingCate(false);
      setLoadingCompany(false);
    }, 500);
  };

  const onClickBuyNow = (nft) => {
    modalRef?.current?.showModal(nft);
  };

  return (
    <>
      <div
        className="container mt-5 d-flex flex-column justify-content-center align-items-start"
        style={{ minHeight: "200px" }}
      >
        {/* <div className="row" style={{ width: "87%", margin: "0 auto" }}>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Products</Breadcrumb.Item>
          </Breadcrumb>
        </div> */}
        <h1
          style={{ width: "87%", margin: "0 auto", fontSize: "80px" }}
          className="color-full mt-2"
        >
          Explore the marketplace
        </h1>
      </div>
      <div className="container">
        <div className="spacer-double"></div>
        <div
          className="row"
          style={{ width: "87%", margin: "0 auto", alignItems: "center" }}
        >
          <div
            className="col-3 item_filter_group"
            style={{ paddingBottom: "0px" }}
          >
            <div className="filter-border" onClick={() => setOpen(!open)}>
              <h4 className="name-filter">Category</h4>
              <div className="svg-filter">{open ? svgRight : svgLeft}</div>
            </div>
          </div>
          <div className="col-2">
            <span style={{ float: "left" }}>
              {total > 0 ? `${total} products` : ""}
            </span>
          </div>
          <div className="col-7 filter-section">
            <Form.Group className="mb-3 filter-form">
              <Select
                placeholder="Select menu"
                styles={customStyles}
                menuContainerStyle={{ zIndex: 9999999 }}
                options={priceSelect}
                onChange={onChangeSelect}
                defaultValue={priceSelect[2]}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row" style={{ width: "87%", margin: "0 auto" }}>
          <div className="col-md-3">
            <Form>
              <div className="item_filter_group">
                {open ? (
                  loadingCate ? (
                    <div className="text-center pt-20">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <div className="content-filter">
                      {!isEmpty(dataCategories) &&
                        dataCategories.map((item, index) => (
                          <div className="mb-2 margin-filter" key={index}>
                            <Form.Check
                              type={"checkbox"}
                              id={`check-box-${index}`}
                            >
                              <Form.Check.Input
                                value={item.name}
                                defaultChecked={renderCheckBox(item.name)}
                                type={"checkbox"}
                                onChange={onChangeCategory}
                              />
                              <Form.Check.Label>{item.name}</Form.Check.Label>
                            </Form.Check>
                          </div>
                        ))}
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>

              <div className="item_filter_group">
                <div
                  className="filter-border"
                  onClick={() => setOpenPrice(!openPrice)}
                >
                  <h4 className="name-filter">Price</h4>
                  <div className="svg-filter">
                    {openPrice ? svgRight : svgLeft}
                  </div>
                </div>

                {openPrice ? (
                  <div className="content-filter">
                    <div className="d-flex-filter">
                      <Form.Control
                        onChange={(event) => run(event.target.value, "min")}
                        placeholder="Min"
                        type="number"
                      />
                      To
                      <Form.Control
                        onChange={(event) => run(event.target.value, "max")}
                        placeholder="Max"
                        type="number"
                      />
                    </div>
                    <input
                      onClick={applyPrice}
                      value={"Apply"}
                      type="button"
                      style={{ width: "100%", marginTop: 10 }}
                      className={`btn-main ${
                        minPrice < 0 || maxPrice < 0
                          ? "opacity-07 cursor-disable"
                          : ""
                      }`}
                      disabled={minPrice < 0 || maxPrice < 0}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="item_filter_group">
                <div
                  className="filter-border"
                  onClick={() => setOpenCompany(!openCompany)}
                >
                  <h4 className="name-filter">Company Name</h4>
                  <div className="svg-filter">
                    {openCompany ? svgRight : svgLeft}
                  </div>
                </div>

                {openCompany ? (
                  loadingCompany ? (
                    <div className="text-center pt-20">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <div className="content-filter">
                      <Form.Control
                        placeholder="Search"
                        onChange={(e) => run(e.target.value, "company")}
                        type="text"
                      />
                      {!isEmpty(dataCompany) &&
                        dataCompany.map((item, index) => (
                          <div
                            className="mb-2 mt-3 margin-filter"
                            key={`company_${index}`}
                          >
                            <Form.Check
                              type={"checkbox"}
                              id={`check-company-${index}`}
                            >
                              <Form.Check.Input
                                value={item._id}
                                defaultChecked={renderCheckBoxCompany(item._id)}
                                type={"checkbox"}
                                onChange={(e) => onChangeCompany(e, item)}
                              />
                              <Form.Check.Label>{item.name}</Form.Check.Label>
                            </Form.Check>
                          </div>
                        ))}
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </Form>
          </div>
          <div className="col-md-9">
            <div className="row">
              <ul className="dFlex">
                {showPrice ? (
                  <li className="content-filter-top">
                    Min ${filter?.minPrice}, Max ${filter?.maxPrice}
                    <span
                      aria-hidden="true"
                      className="icon_close cursor-pointer"
                      onClick={closePrice}
                    ></span>
                  </li>
                ) : (
                  ""
                )}

                {!isEmpty(filterCate)
                  ? filterCate.map((d, index) => (
                      <li
                        className="content-filter-top"
                        key={`filter-cate-${index}`}
                      >
                        {d}
                        <span
                          aria-hidden="true"
                          className="icon_close cursor-pointer"
                          onClick={() => closeCategory(d)}
                        ></span>
                      </li>
                    ))
                  : ""}

                {!isEmpty(filterCompanyHeader)
                  ? filterCompanyHeader.map((d, index) => (
                      <li
                        className="content-filter-top"
                        key={`filter-cate-${index}`}
                      >
                        {d?.name}
                        <span
                          aria-hidden="true"
                          className="icon_close cursor-pointer"
                          onClick={() => closeCompany(d._id)}
                        ></span>
                      </li>
                    ))
                  : ""}
                {showPrice ||
                !isEmpty(filterCate) ||
                !isEmpty(filterCompanyHeader) ? (
                  <li
                    className="content-filter-top clear-all cursor-pointer"
                    onClick={clearAll}
                  >
                    Clear all
                  </li>
                ) : (
                  ""
                )}
              </ul>
              {dataCollections.length > 0 ? (
                <>
                  {dataCollections.map((nft, index) => {
                    return (
                      <NftCard2
                        nft={nft}
                        key={index}
                        className="d-item col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-4"
                        collections={true}
                        height={179}
                        onClickBuyNow={() => onClickBuyNow(nft)}
                      />
                    );
                  })}
                  <div className="col-lg-12">
                    <div className="spacer-single"></div>
                    <div className="pagination-flex">
                      {total && renderPagination(total)}
                    </div>
                  </div>
                </>
              ) : (
                <div className="mt-5 text-center no-data">No Data</div>
              )}

              {/* Modal Add Payment */}
              <ModalAddPayment ref={modalRef} />
            </div>
          </div>
        </div>
        <div
          className="row trending-deal"
          style={{ width: "87%", margin: "0 auto" }}
        >
          <h1>Trending Deals</h1>
          {hotCollections.length > 0 &&
            hotCollections.slice(0, 4).map((item, index) => {
              return (
                <div key={index} className="col-md-3 col-sm-6 trending-item">
                  <Link to={`/detail?id=${item._id}`}>
                    <div className="trending-body">
                      <div>
                        <h6>{truncateString(item.name, 50)}</h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: truncateString(item.description, 100),
                          }}
                        ></p>
                      </div>
                      <div className="trending-image">
                        <img
                          src={item.avatar?.image}
                          alt={item.name}
                          width={70}
                          height={70}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default memo(ItemCollections);
