import { createContext, useContext, useState } from "react";
import { useOnEventCallback } from "../utils/utils";

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [dataStep, setDataStep] = useState(4);
  const [notSupport, setNotSupport] = useState(false);
  const [isExtension, setIsExtension] = useState(false);

  const onChangeDataStep = useOnEventCallback((step) => {
    setDataStep(step);
  });

  const onChangeSupport = useOnEventCallback((data) => {
    setNotSupport(data);
  });

  const onChangeExtension = useOnEventCallback((data) => {
    setIsExtension(data);
  });

  return (
    <MenuContext.Provider
      value={{
        dataStep,
        onChangeDataStep,
        notSupport,
        onChangeSupport,
        isExtension,
        onChangeExtension,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => {
  return useContext(MenuContext);
};
