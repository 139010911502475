import React from "react";

const AndroidDownload = () => {
  return (
    <div
      style={{
        background: "#fff",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          height: "150px",
          background: "#6902C0",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottomRightRadius: "30px",
          borderBottomLeftRadius: "30px",
        }}
      >
        <img src="/img/Koopon_Solid.png" width={200} alt="" />
      </div>
      <h2
        style={{
          textAlign: "center",
          margin: "40px 0 0px 0",
          fontWeight: 800,
        }}
      >
        It's Koo!
      </h2>
      <h3 style={{ textAlign: "center", margin: "30px 4px 4px 4px" }}>
        Download the Koopon mobile app at the Google Play store for all your
        favorite deals.
      </h3>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a href="/">
          <img src="img/google-play.png" width={200} alt="" />
        </a>
      </div>
      <p style={{ textAlign: "center" }}>
        Unique quick deals brought to your phone in seconds.
      </p>
    </div>
  );
};

export default AndroidDownload;
