import React, { useState } from "react";
import { createGlobalStyle } from "styled-components";
import Footer from "../components/Footer";
import ItemCollections from "../components/ItemCollections";
import Header from "../menu/Header";
import { useSearchParams } from "react-router-dom";

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1);
  }
`;

const Collections = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const [openWallet, setOpenWallet] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 9,
    search: search || undefined,
    minPrice: undefined,
    maxPrice: undefined,
    categories: undefined,
    companyIds: undefined,
    sortByDir: undefined,
    sortByDirection: undefined,
    select: undefined,
  });

  const handleKeySearch = (value) => {
    setFilter({ ...filter, search: value.search, select: value.select });
  };

  return (
    <div>
      <GlobalStyles />
      <Header
        isFullWidthMenu={false}
        handleKeySearch={handleKeySearch}
        initKeySearch={search || ""}
        openWallet={openWallet}
        setOpenWallet={setOpenWallet}
        openRegisterModal={openRegisterModal}
        setOpenRegisterModal={setOpenRegisterModal}
      />
      <section>
        <ItemCollections filter={filter} setFilter={setFilter} />
      </section>
      <Footer />
    </div>
  );
};

export default Collections;
