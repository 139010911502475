import React, { useState } from "react";
import { createGlobalStyle } from "styled-components";
import Header from "../menu/Header";
import Footer from "../components/Footer";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
`;
const Policy = () => {
  const [openWallet, setOpenWallet] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  return (
    <div>
      <GlobalStyles />
      <Header
        isFullWidthMenu={true}
        openWallet={openWallet}
        setOpenWallet={setOpenWallet}
        openRegisterModal={openRegisterModal}
        setOpenRegisterModal={setOpenRegisterModal}
      />
      <section>
        <div
          className="container mt-5 d-flex flex-column justify-content-center align-items-start"
          style={{ minHeight: "200px", width: "60%" }}
        >
          <h1
            style={{ fontSize: "70px", fontWeight: "800" }}
            className="color-full mb-0"
          >
            Koopon
          </h1>
          <h1
            style={{ fontSize: "70px", fontWeight: "800" }}
            className="color-full mb-0"
          >
            Privacy Policy
          </h1>
        </div>
        <div
          className="container mt-5 faq-body"
          style={{ minHeight: "200px", width: "60%" }}
        >
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>
            Last Updated: 2/6/2023
          </p>
          <p>
            This Privacy Policy describes how Koopon Inc. (“
            <i>
              <b>Koopon</b>
            </i>
            ,” “
            <i>
              <b>we</b>
            </i>
            ,” or “
            <i>
              <b>us</b>
            </i>
            ”) collect, use, disclose and otherwise process information about
            you. This Privacy Policy applies to information we collect when you
            access or use our websites or make purchases facilitated by Koopon
            (collectively, the “Services”), or when you otherwise interact with
            us, such as through our customer support channels.
          </p>
          <p>
            We may change this Privacy Policy from time to time. If we make
            changes, we will notify you by revising the date at the top of this
            policy. If we make material changes, we will provide you with
            additional notice (such as by adding a statement to the Services or
            sending you a notification). We encourage you to review this Privacy
            Policy regularly to stay informed about our information practices
            and the choices available to you.
          </p>
          <br />
          <p style={{ fontSize: "32px", fontWeight: "600" }}>CONTENTS</p>
          <ul
            className="privacy-policy-list"
            style={{ listStyleType: "none", color: "#01a7bd" }}
          >
            <li>
              <a href="#collection_of_information">Collection of Information</a>
            </li>
            <li>
              <a href="#use_of_information">Use of Information</a>
            </li>
            <li>
              <a href="#targeted_advertising_and_analytics">
                Targeted Advertising and Analytics
              </a>
            </li>
            <li>
              <a href="#disclosure_of_information">Disclosure of Information</a>
            </li>
            <li>
              <a href="#storing_information_on_the_blockchain">
                Storing Information on the Blockchain
              </a>
            </li>
            <li>
              <a href="#contact_us">Contact Us</a>
            </li>
          </ul>
          <br />
          <p
            id="collection_of_information"
            style={{ fontSize: "32px", fontWeight: "600" }}
          >
            COLLECTION OF INFORMATION
          </p>
          <b style={{ fontSize: "18px" }}>Information You Provide to Us</b>
          <p>
            We collect information directly from you when you create an account,
            request customer support, or otherwise communicate with us. The
            categories of information we collect include:
          </p>
          <ul>
            <li>
              <b>Identification Information:</b>{" "}
              <span>
                we collect your name, email address, and crypto wallet ID.
              </span>
            </li>
            <li>
              <b>Financial Information:</b>{" "}
              <span>
                we collect your payment card information through third party
                payment processors.
              </span>
            </li>
            <li>
              <b>Communication Information:</b>{" "}
              <span>
                we collect information included in your communications to or
                about us, including if you file complaints or claims.
              </span>
            </li>
          </ul>
          <p>
            We may also collect any other information you choose to provide.
          </p>
          <b style={{ fontSize: "18px" }}>
            Information We Collect Automatically
          </b>
          <p>
            We automatically collect the following categories of information:
          </p>
          <ul>
            <li>
              <b>Transactional Information:</b>{" "}
              <span>
                we keep a history of your transactions with us, including the
                dates and amounts paid for purchases facilitated by Koopon.
              </span>
            </li>
            <li>
              <b>Internet Activity Information:</b>{" "}
              <span>
                we collect information about how you access our websites,
                including data about the device and network you use, such as
                your hardware model, operating system version, mobile network,
                IP address, unique device identifiers, and browser type. We also
                collect information about your activity on our websites and
                interaction with our communications, such as access times, pages
                viewed, links clicked, and the page you visited before
                navigating to our websites.
              </span>
            </li>
            <li>
              <b>
                Information Collected by Cookies and Similar Tracking
                Technologies:
              </b>{" "}
              <span>
                We use tracking technologies, such as cookies and pixels, to
                collect information about your interactions with our websites
                and communications. These technologies help us improve our
                websites and communications, see which areas and features of our
                websites are popular, count visits, and track clicks. You may be
                able to adjust your browser settings to remove or reject browser
                cookies. Please note that removing or rejecting cookies could
                affect the availability and functionality of our Services.
              </span>
            </li>
          </ul>
          <b style={{ fontSize: "18px" }}>
            Information We Collect from Other Sources
          </b>
          <p>
            We obtain information from other sources. For example, we may
            collect information from [identity verification services,
            advertising networks, data analytics providers, and mailing list
            providers]. This information includes [insert].
          </p>
          <b style={{ fontSize: "18px" }}>Derived Information</b>
          <p>
            We may derive information or draw inferences about you based on the
            information we collect. For example, we may infer that you are
            interested in certain products based on your Transactional and
            Internet Activity Information.
          </p>
          <br />
          <p
            id="use_of_information"
            style={{ fontSize: "32px", fontWeight: "600" }}
          >
            USE OF INFORMATION
          </p>
          <p>
            We use the categories of information we collect for the following
            business and commercial purposes:
          </p>
          <ul>
            <li>
              <b>Service Delivery:</b>
              <span>
                we use information to provide and maintain our Services,
                including to deliver water to you, process payments, and
                authenticate your account.
              </span>
            </li>
            <li>
              <b>Communication:</b>
              <span>
                we use information to communicate with you about [Company] and
                our Services, including to respond to your complaints, claims,
                and inquiries, inform you of price or Services changes, and send
                you other transactional or relationship messages.
              </span>
            </li>
            <li>
              <b>Marketing and Advertising:</b>
              <span>
                we use information to send direct marketing messages (including
                via email) and target advertisements to you on third-party
                platforms and websites as described below. You can opt out of
                direct marketing messages we send by following the instructions
                in those communications (such as by clicking “unsubscribe” in
                the emails) or by contacting us.
              </span>
            </li>
            <li>
              <b>Conducting Analytics:</b>
              <span>
                we use information to monitor and analyze website trends, usage,
                and activities, improve our websites, and generate de-identified
                data.
              </span>
            </li>
            <li>
              <b>Protection and Compliance:</b>
              <span>
                we use information to detect, investigate, and help prevent
                security incidents and other malicious, deceptive, fraudulent,
                or illegal activity, help protect the rights and property of
                Koopon and others, and comply with our legal and financial
                obligations.
              </span>
            </li>
          </ul>
          <br />
          <p
            id="targeted_advertising_and_analytics"
            style={{ fontSize: "32px", fontWeight: "600" }}
          >
            TARGETED ADVERTISING AND ANALYTICS
          </p>
          <p>
            We engage others to provide analytics services, serve
            advertisements, and perform related services across the web and in
            mobile applications. These entities may use cookies, web beacons,
            device identifiers, and other technologies to collect information
            about your use of our website, including your IP address, web
            browser and mobile network information, pages viewed, time spent on
            pages, and links clicked. This information is used to deliver
            advertising targeted to your interests on other companies’ sites or
            mobile apps and to analyze and track data, determine the popularity
            of certain content, and better understand your activity. Some of the
            activities described in this section may constitute “targeted
            advertising,” “sharing,” or “selling” under certain laws.
          </p>
          <p>
            You can also learn more about interest-based ads, or opt out of
            having your web browsing information used for behavioral advertising
            purposes by companies that participate in the Digital Advertising
            Alliance, by visiting{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://www.aboutads.info/choices"
            >
              https://www.aboutads.info/choices
            </a>
            .
          </p>
          <br />
          <p
            id="disclosure_of_information"
            style={{ fontSize: "32px", fontWeight: "600" }}
          >
            DISCLOSURE OF INFORMATION
          </p>
          <p>
            We disclose the categories of information described above as
            follows:
          </p>
          <ul>
            <li>
              <b>Vendors:</b>{" "}
              <span>
                we disclose information to vendors, service providers,
                contractors and consultants that need this information to
                provide services to us, such as companies that assist us with
                web hosting, payment processing, fraud prevention, customer
                service, data enrichment, analytics, and marketing and
                advertising.
              </span>
            </li>
            <li>
              <b>Advertising Partners:</b>{" "}
              <span>
                we disclose information to third parties for the Marketing and
                Advertising purposes described above.
              </span>
            </li>
            <li>
              <b>Professional Advisors:</b>{" "}
              <span>
                we disclose information to our lawyers and other professional
                advisors where necessary to obtain advice or otherwise protect
                and manage our business interests.
              </span>
            </li>
            <li>
              <b>Legal Authorities:</b>{" "}
              <span>
                we may disclose information to legal authorities and others for
                the Protection and Compliance purposes described above,
                including if we believe that disclosure is in accordance with,
                or required by, any applicable law or legal process, including
                lawful requests by public authorities to meet national security
                or law enforcement requirements and if we believe that your
                actions are inconsistent with our user agreements or policies,
                if we believe that you have violated the law, or if we believe
                it is necessary to protect the rights, property, and safety of
                Koopon, our users, the public, or others.
              </span>
            </li>
            <li>
              <b>Corporate Transactions:</b>{" "}
              <span>
                we reserve the right to disclose information in connection with,
                or during negotiations of certain corporate transactions,
                including the merger, sale of company assets, financing, or
                acquisition of all or a portion of our business by another
                company.
              </span>
            </li>
            <li>
              <b>Affiliates:</b>{" "}
              <span>
                we reserve the right to disclose information between and among
                Koopon and any current or future parents, affiliates,
                subsidiaries, and other companies under common control and
                ownership.
              </span>
            </li>
            <li>
              <b>Consent:</b>{" "}
              <span>
                we may disclose information when we have your consent or you
                direct us to do so.
              </span>
            </li>
          </ul>
          <p>
            We also disclose de-identified information that cannot reasonably be
            used to identify you.
          </p>
          <br />
          <p
            id="storing_information_on_the_blockchain"
            style={{ fontSize: "32px", fontWeight: "600" }}
          >
            STORING INFORMATION ON THE BLOCKCHAIN
          </p>
          <p>
            We store certain information related to your purchases through the
            Services on publicly available third party blockchains. For example,
            your wallet ID and purchase details (the date, amount, and product
            purchased) will be stored on-chain. Such information cannot be
            deleted or changed after your purchase.
          </p>
          <br />
          <p id="contact_us" style={{ fontSize: "32px", fontWeight: "600" }}>
            CONTACT US
          </p>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="mailto:support@koopon.xyz"
            >
              support@koopon.xyz
            </a>
            .
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Policy;
