import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Discord } from "./ICons";
import { onGetCategory } from "../../services/services";
import { isEmpty } from "lodash";

const Footer = () => {
  const [dataCate, setDataCate] = useState([]);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const res = await onGetCategory();
      setDataCate(res?.data);
    } catch (error) {
      setDataCate([]);
    }
  };
  return (
    <div className="footer-light">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-4 col-xs-1">
            <div className="widget">
              <div className="de-flex">
                <div className="de-flex-col">
                  <span onClick={() => window.open("", "_self")}>
                    <img
                      alt="Koopon"
                      src="./img/Koopon_Solid-Koo.png"
                      height={48}
                    />
                  </span>
                </div>
              </div>
              <p className="mt-20 under-logo">
                Koopon is revolutionizing the way we shop. Unique, instantaneous
                deals to save you money with all your favorite brands.
              </p>
              <div className="subfooter">
                <div className="de-flex">
                  <div className="de-flex-col">
                    <div className="social-icons">
                      <span
                        onClick={() =>
                          window.open("https://discord.com", "_blank")
                        }
                        className="social-icons-item"
                      >
                        <Discord />
                      </span>
                      <span
                        onClick={() =>
                          window.open("https://instagram.com", "_blank")
                        }
                        className="social-icons-item"
                      >
                        <i className="fa fa-instagram"></i>
                      </span>
                      <span
                        onClick={() =>
                          window.open("https://facebook.com", "_blank")
                        }
                        className="social-icons-item"
                      >
                        <i className="fa fa-facebook fa-lg"></i>
                      </span>
                      <span
                        onClick={() =>
                          window.open("http://linkedin.com", "_blank")
                        }
                        className="social-icons-item"
                      >
                        <i className="fa fa-linkedin fa-lg"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-sm-6 col-xs-1">
            <div className="row footer-right">
              <div
                className="col-md-8 col-sm-6 col-xs-1"
                style={{ padding: 0 }}
              >
                <div className="row">
                  <div className="col-md-12 col-sm-6">
                    <div className="widget">
                      <h5>Categories</h5>
                      <ul className="row">
                        {!isEmpty(dataCate)
                          ? dataCate.map((item, index) => {
                              return (
                                <li key={index + 1} className="col-md-4">
                                  <a
                                    href={`/products?category=${encodeURIComponent(
                                      item.name
                                    )}`}
                                  >
                                    {item.name}
                                  </a>
                                </li>
                              );
                            })
                          : ""}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-1">
                <h5 style={{ marginBottom: "20px" }}>Need help?</h5>
                <p>
                  Contact our customer support at{" "}
                  <a
                    href="mailto:support@koopon.xyz"
                    style={{ textDecoration: "underline" }}
                  >
                    support@koopon.xyz
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="tpb-footer">
          <ul>
            <li>
              <a href="/privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="/tos">Terms Of Service</a>
            </li>
            {/* <li>
              <a href="brandssup">Brands Up</a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Footer;
