import { React } from "react";
import ReactPaginate from "react-paginate";

const DataPagination = ({ itemsPerPage, total, onChangePage }) => {
  const pageCount = Math.ceil(total / itemsPerPage);

  const handlePageClick = (event) => {
    onChangePage(event.selected + 1);
  };

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel="›"
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      previousLabel="‹"
      renderOnZeroPageCount={null}
      containerClassName="justify-content-center pagination"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      activeClassName="active"
      disabledClassName="disabled"
    />
  );
};

export default DataPagination;
