// import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import React, { memo, useEffect, useRef, useState } from "react";
import { onGetHotCollections } from "../../services/services";
import ModalAddPayment from "./ModalAddPayment";
import NftCard2 from "./NftCard2";

const HotCollections = () => {
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const { isLoading, data } = useQuery(
  //   ["hot-collections"],
  //   onGetHotCollections
  // );

  const handleGetHotCollections = async () => {
    setLoading(true);
    const res = await onGetHotCollections();
    if (res && res.data.length > 0) {
      setData(res.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetHotCollections();
  }, []);

  const onClickBuyNow = (nft) => {
    modalRef?.current?.showModal(nft);
  };

  return (
    <>
      {loading ? (
        <div className="text-center">
          <div className="spinner-border" />
        </div>
      ) : !isEmpty(data) ? (
        <>
          {data.slice(0, 5).map((nft, index) => (
            <div className="hot-products" index={index + 1} key={index}>
              <NftCard2
                nft={nft}
                key={index}
                height={179}
                onClickBuyNow={() => onClickBuyNow(nft)}
              />
            </div>
          ))}
        </>
      ) : (
        <div className="text-center">No Data</div>
      )}
      {/* Modal Add Payment */}
      <ModalAddPayment ref={modalRef} />
    </>
  );
};

export default memo(HotCollections);
