import React, { useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";

export const convertCase = (text: string) => {
  const lowercase = text.toLowerCase().replaceAll("_", " ");
  return capitalizeFirstLetter(lowercase);
};

const capitalizeFirstLetter = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const truncateAddress = (address: any) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num: any) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export function useOnEventCallback<T extends (...args: any[]) => any>(
  callback: T,
  options?: { event?: string }
): T {
  const ref = useRef<T>((() => {}) as T);

  if (typeof callback === "function") {
    ref.current = callback;
  }

  return useCallback<T>(
    ((...args) => {
      return ref.current(...args);
    }) as T,
    []
  );
}

export const truncateString = (str: string, length = 50): string => {
  return str.length > length ? str.substring(0, length - 3) + "..." : str;
};
