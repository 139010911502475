import React, { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import Header from "../menu/Header";
import Footer from "../components/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { aboutCarousel } from "../components/constants/index";
import { truncateString } from "../../utils/utils";

import { getConfig, getListPost } from "../../services/services";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
`;

const About = () => {
  const [openWallet, setOpenWallet] = useState(false);
  const [config, setConfig] = useState();
  const [posts, setPosts] = useState([]);
  const [hideConnectButtonOnJumbotron, setHideConnectButtonOnJumbotron] =
    useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  const handleGetConfig = async () => {
    const res = await getConfig();
    if (res && res.data) {
      setConfig(res.data);
    }
  };

  const handleGetPost = async () => {
    const res = await getListPost();
    if (res && res.data) {
      setPosts(res.data);
    }
  };

  useEffect(() => {
    handleGetConfig();
    handleGetPost();
  }, []);

  return (
    <div>
      <GlobalStyles />
      <Header
        isFullWidthMenu={true}
        openWallet={openWallet}
        setOpenWallet={setOpenWallet}
        setHideConnectButtonOnJumbotron={setHideConnectButtonOnJumbotron}
        openRegisterModal={openRegisterModal}
        setOpenRegisterModal={setOpenRegisterModal}
      />
      <section style={{ padding: "60px 0 0 0" }}>
        <div
          className="container mt-5 about-top"
          style={{ minHeight: "200px", width: "55%" }}
        >
          <div className="color-full title">Join the Revolution</div>
          <p style={{ textAlign: "center" }}>
            {config && config?.aboutPageSummary}
          </p>
        </div>
        {config && config.aboutPageMedias.length > 0 && (
          <Slider
            {...aboutCarousel}
            slidesToShow={
              config.aboutPageMedias.length < 4
                ? config.aboutPageMedias.length
                : 4
            }
            slidesToScroll={
              config.aboutPageMedias.length < 4
                ? config.aboutPageMedias.length
                : 4
            }
          >
            {config.aboutPageMedias.map((img, index) => (
              <div className="about-slider" index={index + 1} key={index}>
                <img
                  src={img.url}
                  key={index}
                  alt=""
                  style={{
                    height: "266px",
                    borderRadius: "18px",
                    objectFit: "cover",
                  }}
                />
              </div>
            ))}
          </Slider>
        )}
        <div
          className="container about-title mt-5"
          style={{ minHeight: "100px", textAlign: "center", width: "55%" }}
        >
          <p>{config && config?.aboutPageContent}</p>
        </div>
        <div
          className="container mt-5 about-body"
          style={{ minHeight: "200px", width: "55%" }}
        >
          {posts.length > 0 &&
            posts.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`row post-item ${
                    (index + 1) % 2 ? "post-flex" : "post-flex-reverse"
                  }`}
                >
                  <div className="col-6 post-image">
                    <img src={item.avatar.url} alt="" />
                  </div>
                  <div className="col-6 about-post-content">
                    {/* <div>
                      <span className="line-title"></span>
                      <span>
                        {index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}
                      </span>
                    </div> */}
                    <h4 style={{ textTransform: "uppercase" }}>
                      {truncateString(item.title, 70)}
                    </h4>
                    <p>{truncateString(item.content, 180)}</p>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="about-footer">
          <div className="about-footer-group-button">
            {/* <button className="btn">Learn more</button> */}
            {!hideConnectButtonOnJumbotron && (
              <button onClick={setOpenWallet} className="btn-main-search">
                Connect your wallet
              </button>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
