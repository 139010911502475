import { Elements } from "@stripe/react-stripe-js";
import { useWeb3React } from "@web3-react/core";
import { get, isEmpty } from "lodash";
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Alert, Col, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMenuContext } from "../../context/MenuContext";
import { onGetMethod, subscribeCollection } from "../../services/services";
import { stripePromise } from "../../utils";
import { convertCase } from "../../utils/utils";
import AddPaymentMethod from "./AddPaymentMethod";
import { CloseIcon } from "../components/ICons";

interface Props {
  onClose?(): void;
}
export interface DrawerModalRef {
  showModal(item: any): void;
}

const iconPlus = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 6V12M12.5 12V18M12.5 12H18.5M12.5 12H6.5" stroke="#333333" />
  </svg>
);

const ModalAddPayment: ForwardRefRenderFunction<DrawerModalRef, Props> = (
  props,
  ref
) => {
  const { account } = useWeb3React();
  const [visible, setVisible] = useState(false);
  const [collectionDetail, setCollectionDetail] = useState<any>();

  const { dataStep, onChangeDataStep } = useMenuContext();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [dataCheckPay, setDataCheckPay] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>();
  const [loadingMethod, setLoadingMethod] = useState<boolean>(false);

  const [dataMethod, setDataMethod] = useState<any[]>([]);
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    showModal: async (item) => {
      if (account) {
        await onGetDataPaymentMethod();
        setIsLogin(false);
        if (item) {
          setCollectionDetail(item);
          if (item.price === 0) {
            onChangeDataStep(4);
            try {
              const res = await subscribeCollection({
                collectionId: item._id!,
                amount: 1,
              });
              if (res && res?.status) {
                switch (res.status) {
                  case "COLLECTION_NOT_FOUND":
                  case "FAILURE":
                    setTextError(
                      "Invalid error! Please contact Koopon for help."
                    );
                    break;
                  case "GET_LIMITED_TIME_FOR_BUYING_NFT":
                    onChangeDataStep(5);
                    break;
                  case "SUCCESS":
                    onSwitchToStep3Modal();
                    break;
                  default:
                    setTextError(convertCase(res?.status));
                    break;
                }
              }
            } catch (error) {
              setTextError("Invalid error! Please contact Koopon for help.");
            }
          } else {
            onChangeDataStep(1);
          }
        }
      } else {
        setIsLogin(true);
      }
      setDataCheckPay(undefined);
      setTextError("");
    },
  }));

  const onSwitchToStep3Modal = () => {
    // Payment Success
    onChangeDataStep(3);
  };

  const onGetDataPaymentMethod = async () => {
    setVisible(true);
    setLoadingMethod(true);
    try {
      const res = await onGetMethod();
      if (res) {
        setDataMethod(res?.data);
      }
      setLoadingMethod(false);
    } catch (error) {
      setLoadingMethod(false);
    }
  };

  const onChangeData = (d: any) => {
    const params = {
      collectionId: collectionDetail._id!,
      amount: 1,
      stripeBody: {
        paymentMethodId: d?.id,
        customer: d?.customer,
      },
      shouldSavePaymentMethod: true,
    };
    setDataCheckPay(params);
    setTextError("");
  };

  const onSubmitData = async (d: any) => {
    if (isEmpty(dataCheckPay)) {
      return setTextError("Please choose card or add card to pay now!");
    }
    setLoading(true);
    setTextError("");
    try {
      const res = await subscribeCollection(dataCheckPay);
      if (res && res?.status) {
        switch (res.status) {
          case "COLLECTION_NOT_FOUND":
            setLoading(false);
            setTextError("Please choose card or add card to pay now!");
            break;
          case "SUCCESS":
            if (
              res.data &&
              dataCheckPay.collectionId.toString() === res.data.toString()
            ) {
              onSwitchToStep3Modal();
            }
            break;
          default:
            setLoading(false);
            setTextError(convertCase(res?.status));
            break;
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setTextError("Please choose card or add card to pay now!");
    }
  };

  const onCloseModal = () => {
    setVisible(false);
    onChangeDataStep(4);
    setDataCheckPay(undefined);
  };

  const renderChildren = () => {
    if (dataStep) {
      switch (dataStep) {
        case 4:
          return (
            <div className="checkout" style={{ position: "relative" }}>
              <div className="maincheckout">
                <div className="title-register color-full">Processing...</div>
                <div className="content-register">
                  <div className="text-center mt-2 mb-4">
                    <Spinner animation="border" />
                  </div>
                </div>
              </div>
            </div>
          );
        case 1:
          return (
            <div
              className="checkout checkout-pay-now"
              style={{ position: "relative" }}
            >
              <div className="maincheckout">
                {!loading && (
                  <button className="btn-close" onClick={onCloseModal}>
                    <CloseIcon width={20} height={20} />
                  </button>
                )}
                <div className="title-register color-full">Payment Methods</div>
                <div className="content-register">
                  <>
                    {textError ? (
                      <Alert key={"danger"} variant={"danger"}>
                        {textError}
                      </Alert>
                    ) : (
                      ""
                    )}
                    <Row className="payment-method mt-4">
                      {loadingMethod ? (
                        <div className="text-center mt-2 mb-4">
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <>
                          {dataMethod &&
                            dataMethod.length > 0 &&
                            dataMethod.map((d: any, key: number) => (
                              <Col xs={6} key={`key_data_${key + 1}`}>
                                <div className="item custom-radio">
                                  <Form.Check
                                    type="radio"
                                    name="check-radio"
                                    onChange={() => onChangeData(d)}
                                    label={
                                      <>
                                        <div className="info-method">
                                          <div className="item-method">
                                            <div className="name">
                                              {String(
                                                get(d, "card.brand")
                                              ).toUpperCase()}{" "}
                                              - {get(d, "billing_details.name")}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-number">
                                          •••• •••• •••• {get(d, "card.last4")}
                                        </div>
                                        <div className="expiry-method">
                                          <div className="date">
                                            Expiration{" "}
                                            {get(d, "card.exp_month")}/
                                            {get(d, "card.exp_year")}
                                          </div>
                                        </div>
                                      </>
                                    }
                                  />
                                </div>
                              </Col>
                            ))}
                        </>
                      )}
                      <Col xs={6}>
                        <div className="item">
                          <div
                            className="text-method"
                            onClick={() => {
                              onChangeDataStep(2);
                            }}
                          >
                            <span className="icon-plus">{iconPlus}</span>
                            <span>Add New Payment Method</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {!isEmpty(dataMethod) ? (
                      <div className="btn-main mt-3" onClick={onSubmitData}>
                        {loading ? (
                          <span>
                            <span
                              className="spinner-border spinner-border-sm mright-5"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </span>
                        ) : (
                          `Pay for Deal $${
                            collectionDetail &&
                            collectionDetail.price.toFixed(2)
                          }`
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                </div>
              </div>
            </div>
          );
        case 2:
          return (
            <div
              className="checkout checkout-pay-now"
              style={{ position: "relative" }}
            >
              <div className="maincheckout">
                <button className="btn-close" onClick={onCloseModal}>
                  <CloseIcon width={20} height={20} />
                </button>
                <button
                  className="btn-close btn-back"
                  onClick={() => onChangeDataStep(1)}
                >{`<`}</button>
                <div className="title-register color-full">Buy Now</div>
                <div className="content-register">
                  <Elements stripe={stripePromise}>
                    <AddPaymentMethod
                      collectionId={collectionDetail && collectionDetail._id!}
                      onCloseModal={onSwitchToStep3Modal}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          );
        case 3:
          return (
            <div className="checkout" style={{ position: "relative" }}>
              <div className="maincheckout">
                <button className="btn-close" onClick={onCloseModal}>
                  <CloseIcon width={20} height={20} />
                </button>
                <div className="title-register color-full">Congratulations</div>
                <div className="content-register color-full">
                  You have successfully run your deal, click below to see your
                  deals. Please wait some minutes to update items. Thank you!
                </div>
                <div
                  className="btn-main"
                  onClick={() => {
                    navigate("/my-items");
                    onChangeDataStep(1);
                  }}
                >
                  Go to my items
                </div>
              </div>
            </div>
          );
        case 5:
          return (
            <div className="checkout" style={{ position: "relative" }}>
              <div className="maincheckout">
                <button className="btn-close" onClick={onCloseModal}>
                  <CloseIcon width={20} height={20} />
                </button>
                <div className="title-register color-full">Oops!!!</div>
                <div className="content-register color-full">
                  You have got limited time to buy this Nft.
                </div>
              </div>
            </div>
          );
      }
    }
    return "";
  };

  return (
    <>
      {visible ? renderChildren() : ""}

      {isLogin ? (
        <div className="checkout" style={{ position: "relative" }}>
          <div className="maincheckout">
            <button className="btn-close" onClick={() => setIsLogin(false)}>
              <CloseIcon width={20} height={20} />
            </button>
            <div className="title-register color-full">Oops!!!</div>
            <div className="content-register">
              Please connect your wallet to continue
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default forwardRef(ModalAddPayment);
